import Model, { attr, belongsTo } from '@ember-data/model';

import dayjs from 'dayjs';

import {
  CONNECTION_EXPIRATION_STATUS,
  CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS,
} from 'qonto/constants/connect';

export default class ConsentsBundleModel extends Model {
  /** @type {string} */
  @attr('string') declare grantedScopes: string;

  /** @type {Date} */
  @attr('date') declare grantedAt: Date;

  /** @type {Date} */
  @attr('date') declare expiresAt: Date;

  /** @type {ClientModel} */
  // @ts-expect-error
  @belongsTo('client', { async: false, inverse: null }) client;

  /** @type {MembershipModel} */
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) membership;

  get connectionStatus() {
    if (!this.expiresAt || !dayjs(this.expiresAt).isValid())
      return CONNECTION_EXPIRATION_STATUS.UNKNOWN;

    if (dayjs(this.expiresAt).isBefore(dayjs())) {
      return CONNECTION_EXPIRATION_STATUS.EXPIRED;
    }

    let thresholdDate = dayjs().add(CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS, 'day');

    if (dayjs(this.expiresAt).isBefore(thresholdDate)) {
      return CONNECTION_EXPIRATION_STATUS.EXPIRING;
    }

    return CONNECTION_EXPIRATION_STATUS.ACTIVE;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'consents-bundle': ConsentsBundleModel;
  }
}
