import Model, { attr, belongsTo } from '@ember-data/model';

// @ts-expect-error
import AllowedEmailValidations from 'qonto/validations/allowed-email';

// @ts-expect-error
export default class AllowedEmailModel extends Model.extend(AllowedEmailValidations) {
  // @ts-expect-error
  @attr email;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'allowed-email': AllowedEmailModel;
  }
}
