import Model, { attr, belongsTo } from '@ember-data/model';

export default class ExternalBankRecommendationModel extends Model {
  @attr('string') declare status: string;
  @attr('string') declare type: string;
  @attr('string') declare counterpartyName: string;

  @belongsTo('external-bank-recommendations-bundle', { async: false, inverse: 'recommendations' })
  // @ts-expect-error
  externalBankRecommendationsBundle;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'external-bank-recommendation': ExternalBankRecommendationModel;
  }
}
