import { attr, belongsTo } from '@ember-data/model';

import { equal, reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { TRANSACTION_FEE_OPERATION_SUBTYPES } from 'qonto/constants/transactions';
import Subject from 'qonto/models/subject';

export default class WalletToWalletModel extends Subject {
  @attr('number') declare amount: number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string') declare operationType: string;
  @attr('string') declare operationSubtype: string;
  @attr('string') declare reference: string;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  enrichmentData;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  // @ts-expect-error
  @equal('operationType', 'fee') isFee;
  // @ts-expect-error
  @equal('operationType', 'credit_note') isCreditNote;

  // @ts-expect-error
  @reads('operationSubtype') feeType;

  // @ts-expect-error
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.PHYSICAL_CARD) isPhysicalCard;
  // @ts-expect-error
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.VIRTUAL_CARD) isVirtualCard;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SEPA_INBOUND_TRANSFER)
  // @ts-expect-error
  isSepaInboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SEPA_OUTBOUND_TRANSFER)
  // @ts-expect-error
  isSepaOutboundTransfer;
  // @ts-expect-error
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.ATM) isAtm;
  // @ts-expect-error
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_CARD) isFxCard;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_INBOUND_TRANSFER)
  // @ts-expect-error
  isFxInboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER)
  // @ts-expect-error
  isFxOutboundTransfer;
  // @ts-expect-error
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SUBSCRIPTION) isSubscription;

  get isInternationalOutFee() {
    return [
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_NON_STANDARD_CURRENCY,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_STANDARD_CURRENCY,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_OUR,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_SHA,
    ].includes(this.operationSubtype);
  }

  get isSDDCollectionFee() {
    return [
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_SEND,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_RETURN,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND_LATE,
    ].includes(this.operationSubtype);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'wallet-to-wallet': WalletToWalletModel;
  }
}
