import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class Statement extends Model {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @attr authorizedEmails;
  // @ts-expect-error
  @attr month;
  // @ts-expect-error
  @attr note;
  // @ts-expect-error
  @attr notifyByEmail;
  // @ts-expect-error
  @attr pdfUrl;
  // @ts-expect-error
  @attr slug;
  // @ts-expect-error
  @attr withAttachments;
  // @ts-expect-error
  @attr year;
  // @ts-expect-error
  @attr zipUrl;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  get date() {
    return new Date(this.year, this.month - 1, 1);
  }

  get name() {
    try {
      let date = dateToken({
        // @ts-expect-error
        date: this.date,
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
      });

      if (this.bankAccount.name) {
        return `${date} – ${this.bankAccount.name}`;
      }

      return date;
    } catch {
      return this.slug;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    statement: Statement;
  }
}
