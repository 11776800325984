import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

import { KYC_STATUS } from 'qonto/constants/membership';

export default class IdentitiesKycModel extends Model {
  @attr('string') declare kycStatus: string;
  @attr('string') declare providerName: string;
  @attr('boolean') declare isRenewalPoiRequired: boolean;

  // @ts-expect-error
  @equal('kycStatus', KYC_STATUS.ACCEPTED) kycAccepted;
  // @ts-expect-error
  @equal('kycStatus', KYC_STATUS.PENDING) kycPending;
  // @ts-expect-error
  @equal('kycStatus', KYC_STATUS.REFUSED) kycRefused;
  // @ts-expect-error
  @equal('kycStatus', KYC_STATUS.WAITING_DOCUMENT) kycWaitingDocument;

  // @ts-expect-error
  @equal('providerName', 'fourthline') isProviderFourthline;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'identities-kyc': IdentitiesKycModel;
  }
}
