import Model, { attr, hasMany } from '@ember-data/model';

export default class DocumentCollectionProcessModel extends Model {
  // @ts-expect-error
  @attr reason;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr subjectId;
  // @ts-expect-error
  @attr subjectType;

  @hasMany('document-collection-required-document', {
    async: false,
    inverse: 'documentCollectionProcess',
  })
  // @ts-expect-error
  requiredDocuments;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'document-collection-process': DocumentCollectionProcessModel;
  }
}
