import Model, { attr } from '@ember-data/model';

export default class ClaimModel extends Model {
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr transactionIds;
  // @ts-expect-error
  @attr type;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    claim: ClaimModel;
  }
}
