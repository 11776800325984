import { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import Request from 'qonto/models/request';

export default class RequestSupplierInvoiceModel extends Request {
  // @ts-expect-error
  @attr amount;
  // @ts-expect-error
  @attr createdAt;
  // @ts-expect-error
  @attr dueDate;
  // @ts-expect-error
  @attr processedAt;

  @attr('string', { defaultValue: 'supplier_invoice' }) declare requestType: string;
  // @ts-expect-error
  @attr requestId;
  // @ts-expect-error
  @attr currentReviewerIds;
  @attr('string', { defaultValue: 'pending' }) declare status: string;
  // @ts-expect-error
  @attr supplierName;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('supplier', { async: true, inverse: null }) supplier;
  // @ts-expect-error
  @belongsTo('supplier-invoice', { async: true, inverse: null }) supplierInvoice;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  @waitFor
  async approveRequest() {
    let response = await apiAction(this, { method: 'POST', path: 'approve' });

    return response;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-supplier-invoice': RequestSupplierInvoiceModel;
  }
}
