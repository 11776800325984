import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { NetworkManagerError } from 'qonto/services/network-manager';

export default class SupplierModel extends Model {
  @service declare networkManager: Services['networkManager'];

  @attr('string') declare name: string;
  @attr('string') declare email: string;
  @attr('string') declare phoneNumber: string;
  @attr('number') declare vatNumber: number;
  @attr('number') declare defaultVatRate: number;
  @attr('string') declare tinNumber: string;
  @attr('string') declare recipientCode: string;
  @attr('string') declare streetAddress: string;
  @attr('string') declare zipCode: string;
  @attr('string') declare provinceCode: string;
  @attr('string') declare city: string;
  @attr('string') declare countryCode: string;
  // @ts-expect-error
  @attr('hash') outstandingBalance;
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;
  @attr('string') declare iban: string;
  @attr('string', { defaultValue: SUPPLIER_STATUSES.UNARCHIVED }) declare status: string;
  @attr('string') declare organizationId: string;

  @waitFor
  // @ts-expect-error
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      // @ts-expect-error
      if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
        // @ts-expect-error
        let errors = this.#errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }
      throw error;
    }
  }

  // this method comes from
  // https://github.com/emberjs/data/blob/f4bfbaa148b67104d0022fd176faa0f4a612289a/packages/store/addon/-private/system/errors-utils.js#L122
  // override to support nested errors
  // @ts-expect-error
  #errorsArrayToHash(errors) {
    let out = {};
    if (errors) {
      // @ts-expect-error
      errors.forEach(error => {
        if (error.source && error.source.pointer) {
          let key = error.source.pointer;

          if (key) {
            // @ts-expect-error
            out[key] = out[key] || [];
            // only update: use the code as the message if it exists
            // @ts-expect-error
            out[key].push(error.code || error.details || error.title);
          }
        }
      });
    }
    return out;
  }

  @waitFor
  async archive() {
    await apiAction(this, { method: 'POST', path: 'archive' });
    this.store.pushPayload('supplier', { ...this, status: SUPPLIER_STATUSES.ARCHIVED });
  }

  @waitFor
  async unarchive() {
    await apiAction(this, { method: 'POST', path: 'unarchive' });
    this.store.pushPayload('supplier', { ...this, status: SUPPLIER_STATUSES.UNARCHIVED });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    supplier: SupplierModel;
  }
}
