import Model, { attr, belongsTo } from '@ember-data/model';

export default class BulkBeneficiaryModel extends Model {
  // @ts-expect-error
  @belongsTo('multiBeneficiary', { async: true, inverse: 'beneficiaries' }) multiBeneficiary;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  @attr('string') declare activityTag: string;
  @attr('string') declare bic: string;
  @attr('string') declare email: string;
  @attr('string', { defaultValue: '' }) declare iban: string;
  @attr('string') declare name: string;
  @attr('boolean', { defaultValue: true }) declare selected: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bulk-beneficiary': BulkBeneficiaryModel;
  }
}
