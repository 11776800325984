import Model, { attr, belongsTo } from '@ember-data/model';

// @ts-expect-error
import EInvoiceActivationValidations from 'qonto/validations/e-invoice-activation';

// @ts-expect-error
export default class EinvoiceActivationModel extends Model.extend(EInvoiceActivationValidations) {
  /** @type {boolean} */
  // @ts-expect-error
  @attr eInvoicingActivated;
  /** @type {boolean} */
  // @ts-expect-error
  @attr eInvoicingTermsAndConditionsAccepted;
  /** @type {string | null} */
  // @ts-expect-error
  @attr fiscalCode;
  /** @type {string | null} */
  // @ts-expect-error
  @attr provinceCode;
  /** @type {string | null} */
  // @ts-expect-error
  @attr taxRegime;
  /** @type {string | null} */
  // @ts-expect-error
  @attr legalTvaNumber;

  /** @type {Organization} */
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'e-invoice-activation': EinvoiceActivationModel;
  }
}
