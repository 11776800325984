import Model, { attr, belongsTo } from '@ember-data/model';

import { GBR_LEGAL_CODE } from 'qonto/constants/company-types';
import { DE_FREELANCERS_LEGAL_CODES } from 'qonto/constants/legal-codes';

// @ts-expect-error
import KycKybUpdateOrganizationChangeRequestValidations from '../validations/kyc-kyb-update-organization-change-request';

// @ts-expect-error
export default class KycKybUpdateOrganizationChangeRequest extends Model.extend(
  KycKybUpdateOrganizationChangeRequestValidations
) {
  /**
   * @type string
   * @example "We are doing cool stuff"
   */
  // @ts-expect-error
  @attr activityDescription;

  /**
   * @type string
   * @example "Paris"
   */
  // @ts-expect-error
  @attr addressCity;

  /**
   * @type string
   * @example "FR"
   */
  // @ts-expect-error
  @attr addressCountry;

  /**
   * @type string
   * @example "Rue La Fayette"
   */
  // @ts-expect-error
  @attr addressFirstLine;

  /**
   * @type string | null
   * @example "20B"
   */
  // @ts-expect-error
  @attr addressSecondLine;

  /**
   * @type string | null
   * @example "Suite 10"
   */
  // @ts-expect-error
  @attr addressThirdLine;

  /**
   * @type string
   * @example "75009"
   */
  // @ts-expect-error
  @attr addressZipCode;

  /**
   * The contact email for the organization
   * @type string
   * @example "john.doe@qonto.com"
   */
  // @ts-expect-error
  @attr contactEmail;

  /**
   * Income of the organization.
   * @type string
   * @example "880 000 €"
   */
  // @ts-expect-error
  @attr estimatedYearlyIncome;

  /**
   * Intra-community VAT number.
   * @type string | null
   * @example "IT12345678901"
   */
  // @ts-expect-error
  @attr euVatNumber;

  /**
   * Legal code of the organization.
   * @type string
   * @example "4110"
   */
  // @ts-expect-error
  @attr legalCode;

  /**
   * Official name of the organization.
   * @type string
   * @example "Olinda"
   */
  // @ts-expect-error
  @attr legalName;

  /**
   * Legal Number of the organization.
   * @type string
   * @example "ABC12345678"
   */
  // @ts-expect-error
  @attr legalNumber;

  /**
   * @type string
   * @example "2015-04-20"
   */
  @attr('string') declare legalRegistrationDate: string;

  /**
   * The NAF code representing the activity sector of the organization.
   *
   * This field may be `null` if the organization has not provided the
   * information yet.
   *
   * @type string | null
   * @example "1337Z"
   */
  // @ts-expect-error
  @attr legalSector;

  /**
   * Trade name of the organization (optional).
   * @type string | null
   * @example "Qonto"
   */
  // @ts-expect-error
  @attr name;

  /**
   * The two-letter country code of the country where the person pays
   * their taxes.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   * @example "FR"
   */
  // @ts-expect-error
  @attr taxCountry;

  /**
   * The tax identification number of the person in the country
   * represented by `taxCountry`.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   * @example "88011876500010"
   */
  // @ts-expect-error
  @attr taxIdentificationNumber;

  /**
   * The website of the organization
   * @type string
   * @example "www.google.com"
   */
  // @ts-expect-error
  @attr websiteUrl;

  // @ts-expect-error
  @attr natureOfOperations;

  // business finances
  // @ts-expect-error
  @attr annualTurnover;
  // @ts-expect-error
  @attr estimatedAnnualTurnover;
  // @ts-expect-error
  @attr yearlyTransactionVolume;
  // @ts-expect-error
  @attr maxSinglePaymentAmount;

  /** @type {KycKybUpdateProcess} */
  @belongsTo('kycKybUpdateProcess', {
    async: true,
    inverse: 'kycKybUpdateOrganizationChangeRequest',
  })
  // @ts-expect-error
  kycKybUpdateProcess;

  get needsTaxNumber() {
    return (
      this.kycKybUpdateProcess.get('organization.legalCountry') !== 'FR' || this.taxCountry !== 'FR'
    );
  }

  needsTaxData = true;

  get needNewRegistrationCertificate() {
    let organization = this.kycKybUpdateProcess.get('organization');

    if (
      this.legalName !== organization.get('legalName') ||
      this.name !== organization.get('tradeName') ||
      this.legalCode !== organization.get('legalCode') ||
      this.legalSector !== organization.get('legalSector') ||
      this.addressFirstLine !== organization.get('address.firstLine') ||
      this.addressSecondLine !== organization.get('address.secondLine') ||
      this.addressThirdLine !== organization.get('address.thirdLine') ||
      this.addressZipCode !== organization.get('address.zipcode') ||
      this.addressCity !== organization.get('address.city') ||
      this.addressCountry !== organization.get('address.country') ||
      this.euVatNumber !== organization.get('euVatNumber') ||
      this.taxCountry !== organization.get('taxResidenceCountry')
    ) {
      return true;
    }

    return false;
  }

  get isGbR() {
    return this.legalCode === GBR_LEGAL_CODE;
  }

  get isGermanFreelancer() {
    return DE_FREELANCERS_LEGAL_CODES.includes(this.legalCode);
  }

  get hasMissingInformation() {
    let legalCountry = this.kycKybUpdateProcess.get('organization.legalCountry');
    let isItaly = legalCountry === 'IT';

    let isNatureOfOperationsMissing =
      !this.natureOfOperations ||
      (Array.isArray(this.natureOfOperations) && this.natureOfOperations.length === 0);

    return (
      !this.addressCity ||
      !this.addressCountry ||
      !this.addressFirstLine ||
      !this.addressZipCode ||
      !this.contactEmail ||
      !this.legalCode ||
      !this.legalNumber ||
      !this.legalName ||
      !this.legalSector ||
      // Quick fix for italy. In the next features we should consider all countries,
      // and adapt these depending on the required fields for each country/form.
      (isItaly &&
        (!this.taxCountry ||
          (this.needsTaxNumber && !this.taxIdentificationNumber) ||
          !this.estimatedAnnualTurnover ||
          !this.yearlyTransactionVolume ||
          !this.maxSinglePaymentAmount ||
          isNatureOfOperationsMissing ||
          !this.activityDescription))
    );
  }

  get differsFromOriginal() {
    let organization = this.kycKybUpdateProcess.get('organization');

    if (
      this.activityDescription === organization.get('activityDescription') &&
      this.addressCity === organization.get('address').city &&
      this.addressCountry === organization.get('address').country &&
      this.addressFirstLine === organization.get('address').firstLine &&
      this.addressSecondLine === organization.get('address').secondLine &&
      this.addressThirdLine === organization.get('address').thirdLine &&
      this.addressZipCode === organization.get('address').zipcode &&
      this.contactEmail === organization.get('contactEmail') &&
      this.estimatedYearlyIncome === organization.get('estimatedYearlyIncome') &&
      this.euVatNumber === organization.get('euVatNumber') &&
      this.legalCode === organization.get('legalCode') &&
      this.legalName === organization.get('legalName') &&
      this.legalNumber === organization.get('legalNumber') &&
      this.legalSector === organization.get('legalSector') &&
      this.name === organization.get('tradeName') &&
      this.taxCountry === organization.get('taxResidenceCountry') &&
      this.taxIdentificationNumber === organization.get('taxResidenceTaxIdentificationNumber') &&
      this.websiteUrl === organization.get('websiteUrl') &&
      this.legalRegistrationDate === organization.get('legalRegistrationDate')
    ) {
      return false;
    } else {
      return true;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'kyc-kyb-update-organization-change-request': KycKybUpdateOrganizationChangeRequest;
  }
}
