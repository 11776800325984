import Model, { attr } from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  /** @type {string} */
  // @ts-expect-error
  @attr last4;
  /** @type {string} */
  // @ts-expect-error
  @attr expDate;
  /** @type {string} */
  // @ts-expect-error
  @attr type;
  /** @type {string} */
  // @ts-expect-error
  @attr organizationId;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-method': PaymentMethodModel;
  }
}
