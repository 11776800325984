import Model, { attr } from '@ember-data/model';

export default class F24OthersTaxItemModel extends Model {
  // @ts-expect-error
  @attr branchCode;
  // @ts-expect-error
  @attr positionCode;
  // @ts-expect-error
  @attr taxType;
  // @ts-expect-error
  @attr referencePeriodFrom;
  // @ts-expect-error
  @attr referencePeriodTo;
  @attr('number') declare taxAmount: number;
  @attr('number') declare compensationAmount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/others-tax-item': F24OthersTaxItemModel;
  }
}
