import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';

// @ts-expect-error
import AddressValidations from 'qonto/validations/address';

// @ts-expect-error
export default class AddressModel extends Model.extend(AddressValidations) {
  @service declare intl: Services['intl']; //this service is used in validator
  @attr('string') declare contactName: string;
  @attr('string') declare firstLine: string;
  @attr('string') declare secondLine: string;
  @attr('string') declare thirdLine: string;
  @attr('string') declare zipcode: string;
  @attr('string') declare city: string;
  @attr('string') declare country: string;

  get fullAddress() {
    let addressFirstPart = [this.firstLine, this.secondLine, this.thirdLine, this.zipcode]
      .filter(part => !isEmpty(part))
      .join(', ');
    let addressSecondPart = [this.city, this.country].filter(part => !isEmpty(part)).join(', ');

    return `${addressFirstPart} ${addressSecondPart}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    address: AddressModel;
  }
}
