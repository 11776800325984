import Model, { attr, belongsTo } from '@ember-data/model';

export default class PartnerOrganization extends Model {
  @attr('string') declare legalName: string;
  // @ts-expect-error
  @belongsTo('registration', { async: true, inverse: 'organization' }) registration;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-organization': PartnerOrganization;
  }
}
