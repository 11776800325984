import Model, { attr } from '@ember-data/model';

export default class ReceivableInvoicePaymentModel extends Model {
  // @ts-expect-error
  @attr conditions;
  // @ts-expect-error
  @attr method;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/payment': ReceivableInvoicePaymentModel;
  }
}
