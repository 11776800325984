import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import Subject from 'qonto/models/subject';

export default class PagopaPaymentModel extends Subject {
  @service declare networkManager: Services['networkManager'];

  // @ts-expect-error
  @attr amount;
  // @ts-expect-error
  @attr fiscalCode;
  // @ts-expect-error
  @attr creditorName;
  // @ts-expect-error
  @attr noticeNumber;
  // @ts-expect-error
  @attr feeEstimateAmount;
  // @ts-expect-error
  @attr totalAmount;

  /**
   * Unique ID of the payment (Identificativo Univoco Versamento).
   */
  // @ts-expect-error
  @attr iuv;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  @waitFor
  async verify() {
    // @ts-expect-error
    let { fiscal_code, notice_number } = this.serialize();
    let data = { pagopa_payment: { fiscal_code, notice_number } };

    try {
      let response = await apiAction(this, { method: 'POST', path: 'verify', data });

      // @ts-expect-error
      let serializer = this.store.serializerFor('pagopaPayment');
      // @ts-expect-error
      let hash = serializer.normalizeSingleResponse(
        this.store,
        this.constructor,
        response,
        null,
        'findRecord'
      );

      this.setProperties(hash.data.attributes);
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = error.errors
          // @ts-expect-error
          .filter(e => Boolean(e.source?.pointer))
          // @ts-expect-error
          .reduce((acc, { source, code }) => {
            let attribute = source.pointer.split('/').pop();
            return {
              ...acc,
              [attribute]: code,
            };
          }, {});
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pagopa-payment': PagopaPaymentModel;
  }
}
