import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import { reads } from 'macro-decorators';

// @ts-expect-error
import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import { getCurrentParisDateString } from 'qonto/utils/date';
// @ts-expect-error
import TransferValidations from 'qonto/validations/transfer';

// @ts-expect-error
export default class BulkTransferModel extends Model.extend(TransferValidations) {
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];

  @attr('boolean') declare notifyByEmail: boolean;
  @attr('boolean', { defaultValue: true }) declare selected: boolean;
  @attr('string') declare activityTagCode: string;
  @attr('number') declare bulkId: number;
  @attr('string') declare activityTag: string;
  @attr('string') declare sequentialId: string;
  @attr('string') declare localAmountCurrency: string;
  @attr('string', { defaultValue: '' }) declare reference: string;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string', { defaultValue: 'scheduled' }) declare operationType: string;
  @attr('string', { defaultValue: ACCOUNT_TYPES.IBAN }) declare accountType: string;
  @attr('number', { defaultValue: 0 }) declare amount: number;
  @attr('date') declare createdAt: Date;
  @attr('string', { defaultValue: getCurrentParisDateString }) declare scheduledDate: string;
  // @ts-expect-error
  @attr() name;
  // @ts-expect-error
  @attr() email;
  // @ts-expect-error
  @attr({ defaultValue: '' }) iban;
  // @ts-expect-error
  @attr() bic;

  // @ts-expect-error
  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('multiTransfer', { async: true, inverse: 'transfers' }) multiTransfer;

  // @ts-expect-error
  @hasMany('attachment', { async: true, inverse: null }) attachments;

  originalBeneficiaryEmail = null;

  // @ts-expect-error
  @reads('attachments.content.0') attachment;

  get savedAttachments() {
    // @ts-expect-error
    return this.attachments.filter(attachment => !attachment.isNew);
  }

  get attachmentCount() {
    return this.savedAttachments.length;
  }

  get isScheduled() {
    return this.operationType === 'scheduled';
  }

  get shouldHaveAttachments() {
    // @ts-expect-error
    let isAboveMaxAmount = amount => {
      let maxAmountInCents =
        this.organizationManager.organization.transferSettings?.max_amount_without_attachment_cents;
      let maxAmount = maxAmountInCents / 100;

      return amount >= maxAmount;
    };

    return isAboveMaxAmount(this.amount);
  }

  get attachmentsFiles() {
    // @ts-expect-error
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  get hasMultiTransfer() {
    return Boolean(this.belongsTo('multiTransfer').value());
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bulk-transfer': BulkTransferModel;
  }
}
