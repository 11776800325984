import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

export default class DirectDebitHoldModel extends Model {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @attr() amount;
  @attr('string') declare guardingRate: string;
  @attr('string') declare slug: string;
  @attr('date') declare executionDate: Date;
  @attr('date') declare releaseDate: Date;
  @attr('boolean') declare isReleased: boolean;
  @attr('string') declare directDebitCollectionTransactionId: string;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('transaction', { async: false, inverse: null }) transaction;

  get guardingPercentage() {
    if (this.guardingRate) {
      return `${Number(this.guardingRate) * 100}%`;
    }

    return null;
  }

  get holdAmount() {
    if (this.amount.value && this.guardingRate) {
      return Number(this.amount.value).toFixed(2);
    }
    return null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit-hold': DirectDebitHoldModel;
  }
}
