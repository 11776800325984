import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

import type { Thumbnail } from 'qonto/react/graphql';

interface File {
  fileName: string;
  fileContentType: string;
  fileSize: string;
  fileUrl: string;
  status: string;
  downloadUrl: string;
}

export default class AttachmentModel extends Model {
  @attr('string') declare slug: string | null;
  @attr('string') declare downloadUrl: string;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  declare createdAt: Date;
  @attr('file') declare file: File;
  @attr('file') declare probativeAttachment: File;
  @attr('file') declare thumbnail: Thumbnail;
  @attr('string') declare attachmentType: string;
  @attr('string') declare documentType: string;

  // @ts-expect-error
  @equal('probativeAttachment.status', 'available') isProbated;

  vatStatus = null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attachment: AttachmentModel;
  }
}
