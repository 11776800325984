// @ts-nocheck
import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { capitalize } from '@ember/string';

import { PAYMENT_STATUSES } from 'qonto/constants/card-acquirer';

export default class CardAcquirerPaymentModel extends Model {
  @service declare intl: Services['intl'];

  @attr() amount;
  @attr('string') declare reference: string;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('membership', { async: true, inverse: null }) membership;
  @belongsTo('card-acquirer-payout', { async: true, inverse: null }) payout;

  @attr('string') declare status: string;
  @attr('string') declare paymentMethod: string;
  @attr('string') declare cardLast4: string;
  @attr('date') declare processingAt: Date;
  @attr('date') declare completedAt: Date;
  @attr('date') declare declinedAt: Date;

  get card() {
    if (!this.cardLast4 && !this.paymentMethod) {
      return '-';
    }
    if (!this.cardLast4) {
      return capitalize(this.paymentMethod);
    }
    if (!this.paymentMethod) {
      return `•• ${this.cardLast4}`;
    }
    return `${capitalize(this.paymentMethod)} •• ${this.cardLast4}`;
  }

  get displayedStatus() {
    switch (this.status) {
      case PAYMENT_STATUSES.COMPLETED:
        return {
          level: 'validated',
          text: this.intl.t('tap-to-pay.payments.status.completed'),
        };
      case PAYMENT_STATUSES.DECLINED:
        return {
          level: 'error',
          text: this.intl.t('tap-to-pay.payments.status.declined'),
        };
      default:
      case PAYMENT_STATUSES.PROCESSING:
        return {
          level: 'in-progress',
          text: this.intl.t('tap-to-pay.payments.status.processing'),
        };
    }
  }

  get executionDate() {
    if (this.status === PAYMENT_STATUSES.DECLINED) {
      return this.declinedAt;
    }
    return this.processingAt;
  }

  get avatarInfo() {
    return {
      smallLogo: '/icon/card/card-acquirer-payments/money-bag.svg',
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'card-acquirer-payment': CardAcquirerPaymentModel;
  }
}
