import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class SwiftIncomeModel extends Subject {
  // @ts-expect-error
  @attr amount;
  // @ts-expect-error
  @attr amountCurrency;
  // @ts-expect-error
  @attr localAmount;
  // @ts-expect-error
  @attr localAmountCurrency;
  // @ts-expect-error
  @attr exchangeRate;
  // @ts-expect-error
  @attr emitterAccountIdentifier;
  // @ts-expect-error
  @attr emitterBankIdentifier;
  // @ts-expect-error
  @attr emitterNameAddress;
  // @ts-expect-error
  @attr reference;

  // @ts-expect-error
  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'swift-income': SwiftIncomeModel;
  }
}
