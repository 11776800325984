import { attr, belongsTo } from '@ember-data/model';

import Request from 'qonto/models/request';

export default class RequestVirtualCard extends Request {
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bank-account', { async: true, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) approver;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) holder;
  // @ts-expect-error
  @belongsTo('card', { async: true, inverse: null }) card;

  @attr('number') declare paymentMonthlyLimit: number;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr currency;
  // @ts-expect-error
  @attr processedAt;
  // @ts-expect-error
  @attr createdAt;
  @attr('string', { defaultValue: 'virtual_card' }) declare requestType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-virtual-card': RequestVirtualCard;
  }
}
