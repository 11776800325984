import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import { equal } from 'macro-decorators';

// @ts-expect-error
import { INSURANCE_CONTRACT_STATUS, INSURANCE_PRODUCT_TYPES } from 'qonto/constants/insurance-hub';

export default class InsuranceContractModel extends Model {
  @service declare intl: Services['intl'];

  @attr('string') declare contractId: string;
  @attr('string') declare name: string;
  @attr('string') declare paymentFrequency: string;
  @attr('string') declare providerSlug: string;
  @attr('string') declare serviceUrl: string;
  @attr('string') declare status: string;
  @attr('string') declare type: string;
  @attr('string') declare troubleshootingUrl: string;
  @attr('date') declare expirationDate: Date;
  @attr('date') declare startDate: Date;
  @attr('date') declare renewalDate: Date;
  // @ts-expect-error
  @attr price;
  // @ts-expect-error
  @attr documents;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) membership;

  // @ts-expect-error
  @equal('status', INSURANCE_CONTRACT_STATUS.EXPIRED) isExpired;

  get policyProductType() {
    let productTypes = {
      // Stello (FR)
      [INSURANCE_PRODUCT_TYPES.BUSINESS_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.business_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.COLLECTIVE_HEALTCARE]: this.intl.t(
        'insurance-hub.policies.product-type.collective_healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.CYBER]: this.intl.t('insurance-hub.policies.product-type.cyber'),
      [INSURANCE_PRODUCT_TYPES.DECENNIAL]: this.intl.t(
        'insurance-hub.policies.product-type.decennial'
      ),
      [INSURANCE_PRODUCT_TYPES.HEALTHCARE]: this.intl.t(
        'insurance-hub.policies.product-type.healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.IT_HARDWARE_DAMAGE]: this.intl.t(
        'insurance-hub.policies.product-type.it_hardware_damage'
      ),
      [INSURANCE_PRODUCT_TYPES.KEY_PERSON]: this.intl.t(
        'insurance-hub.policies.product-type.key_person'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_PROTECTION]: this.intl.t(
        'insurance-hub.policies.product-type.legal_protection'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_SERVICE]: this.intl.t(
        'insurance-hub.policies.product-type.legal_service'
      ),
      [INSURANCE_PRODUCT_TYPES.MOTOR_FLEET]: this.intl.t(
        'insurance-hub.policies.product-type.motor_fleet'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK]: this.intl.t(
        'insurance-hub.policies.product-type.multi_risk'
      ),
      [INSURANCE_PRODUCT_TYPES.OFFICE]: this.intl.t('insurance-hub.policies.product-type.office'),
      [INSURANCE_PRODUCT_TYPES.PUBLIC_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.public_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.REVENUE_LOSS]: this.intl.t(
        'insurance-hub.policies.product-type.revenue_loss'
      ),

      // Surein (DE)
      [INSURANCE_PRODUCT_TYPES.BUSINESS_LIABILITY_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.business_liability_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.CYBER_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.cyber_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.FINANCIAL_LOSS_PROTECTION_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.financial_loss_protection_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_PROTECTION_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.legal_protection_surein'
      ),

      // Facile (IT)
      [INSURANCE_PRODUCT_TYPES.PERSONAL_PROTECTION]: this.intl.t(
        'insurance-hub.policies.product-type.personal-protection-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK_FOR_BUSINESS]: this.intl.t(
        'insurance-hub.policies.product-type.multi-risk-business-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK_FOR_CORPORATE_ENTITIES]: this.intl.t(
        'insurance-hub.policies.product-type.multi-risk-corporate-entities-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK_FOR_PROFESSIONAL]: this.intl.t(
        'insurance-hub.policies.product-type.multi-risk-for-professional-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.CATASTROPHIC_DAMAGE]: this.intl.t(
        'insurance-hub.policies.product-type.catastrophic-damage-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.INJURIES]: this.intl.t(
        'insurance-hub.policies.product-type.injuries-29risk'
      ),
      [INSURANCE_PRODUCT_TYPES.TERM_LIFE]: this.intl.t(
        'insurance-hub.policies.product-type.term-life-29risk'
      ),
    };

    // @ts-expect-error
    return productTypes[this.type] || this.name;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'insurance-contract': InsuranceContractModel;
  }
}
