import Model, { attr } from '@ember-data/model';

export default class OnBehalfRegistrationModel extends Model {
  @attr('string') declare onBehalfStatus: string; // "PENDING" or "COMPLETED"

  // @ts-expect-error
  @attr('hash') poweredMemberships;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'on-behalf-registration': OnBehalfRegistrationModel;
  }
}
