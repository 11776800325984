import Model, { attr } from '@ember-data/model';

export default class ReceivableInvoiceWithholdingTaxModel extends Model {
  // @ts-expect-error
  @attr type;
  // @ts-expect-error
  @attr rate;
  // @ts-expect-error
  @attr reason;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/withholding-tax': ReceivableInvoiceWithholdingTaxModel;
  }
}
