import Model, { attr } from '@ember-data/model';

import { reads } from 'macro-decorators';

export default class FileModel extends Model {
  @attr('date') declare createdAt: Date;
  // @ts-expect-error
  @attr filename;
  // @ts-expect-error
  @attr filesizeBytes;
  // @ts-expect-error
  @attr url;

  // aliases created for retro-compatibility with the UIKit file-preview component
  // @ts-expect-error
  @reads('filename') fileName;
  // @ts-expect-error
  @reads('filesizeBytes') fileSize;
  // @ts-expect-error
  @reads('url') fileUrl;

  // compute the file's content-type based on its URL
  // this attribute is required by the UIKit file-preview component
  get fileContentType() {
    let extension = this.filename.split('.').pop();
    if (extension.match(/jpg|jpeg|png|gif'/i)) {
      return 'image';
    } else if (extension.match(/pdf/i)) {
      return 'pdf';
    }
    return undefined;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    file: FileModel;
  }
}
