import Model, { attr } from '@ember-data/model';

export default class ApprovalWorkflowStateConditionModel extends Model {
  // @ts-expect-error
  @attr field;
  // @ts-expect-error
  @attr operation;
  // @ts-expect-error
  @attr value;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow-state/condition': ApprovalWorkflowStateConditionModel;
  }
}
