import Model, { attr, belongsTo } from '@ember-data/model';

export default class PeriodComputedAmountsModel extends Model {
  // @ts-expect-error
  @belongsTo('period', { async: false, inverse: 'computedAmounts' }) period;

  // @ts-expect-error
  @attr amountSettled;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'period-computed-amount': PeriodComputedAmountsModel;
  }
}
