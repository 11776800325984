import Model, { attr, belongsTo } from '@ember-data/model';

export default class VatModel extends Model {
  /** @type {{ value: string, currency: ?string }} */
  // @ts-expect-error
  @attr amount;

  /** @type {{ value: string, currency: ?string }} */
  // @ts-expect-error
  @attr amountExcludingVat;

  /** @type {number} */
  // @ts-expect-error
  @attr rate;

  /** @type {Transaction} */
  // @ts-expect-error
  @belongsTo('transaction', { async: false, inverse: null }) transaction;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    vat: VatModel;
  }
}
