import Model, { attr } from '@ember-data/model';

export default class ApprovalWorkflowStepModel extends Model {
  // @ts-expect-error
  @attr field;
  // @ts-expect-error
  @attr operation;
  // @ts-expect-error
  @attr value;
  // @ts-expect-error
  @attr order;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow/step': ApprovalWorkflowStepModel;
  }
}
