import Model, { attr } from '@ember-data/model';

export default class ReceivableInvoicesUploadModel extends Model {
  @attr declare filename: string;
  @attr declare filesizeBytes: number;
  @attr declare url: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoices-upload': ReceivableInvoicesUploadModel;
  }
}
