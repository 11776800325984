import { attr, belongsTo, hasMany } from '@ember-data/model';

import { reads } from 'macro-decorators';

import Request from 'qonto/models/request';

export default class ExpenseReportModel extends Request {
  // @ts-expect-error
  @attr amount;

  // @ts-expect-error
  @attr createdAt;
  @attr('string', { defaultValue: 'expense_report' }) declare requestType: string;
  // @ts-expect-error
  @attr reference;
  // @ts-expect-error
  @attr note;
  // @ts-expect-error
  @attr vatRate;
  // @ts-expect-error
  @attr vatAmount;
  // @ts-expect-error
  @attr iban;
  // @ts-expect-error
  @attr declinedNote;
  // @ts-expect-error
  @attr attachmentIds;
  // @ts-expect-error
  @attr requestId;
  @attr('string', { defaultValue: 'pending' }) declare status: string;
  @attr('boolean', { defaultValue: false }) declare bankless: boolean;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @hasMany('attachment', { async: true, inverse: null }) attachments;
  // @ts-expect-error
  @hasMany('label', { async: false, inverse: null }) labels;

  // @ts-expect-error
  @reads('attachments.length') attachmentCount;
  // accessing `ManyArray` using `content` property
  // @ts-expect-error
  @reads('attachments.content.0') attachment;

  get attachmentsFiles() {
    // @ts-expect-error
    return this.attachments.map(at => at.file).filter(Boolean);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-expense-report': ExpenseReportModel;
  }
}
