import Model, { attr, belongsTo } from '@ember-data/model';

export default class HubApplicationModel extends Model {
  // @ts-expect-error
  @attr slug;
  // @ts-expect-error
  @attr name;
  // @ts-expect-error
  @attr params;
  // @ts-expect-error
  @belongsTo('hub-activation', { async: false, inverse: 'application' }) activation;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hub-application': HubApplicationModel;
  }
}
