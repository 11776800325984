import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

export default class HubNotificationRuleModel extends Model {
  // @ts-expect-error
  @attr ibans;
  // @ts-expect-error
  @attr trigger;
  // @ts-expect-error
  @attr field;
  // @ts-expect-error
  @attr filter;
  // @ts-expect-error
  @attr value;
  // @ts-expect-error
  @attr channelId;
  // @ts-expect-error
  @attr channelName;

  // @ts-expect-error
  @belongsTo('hub-connection', { async: false, inverse: 'rules' }) connection;

  @waitFor
  async estimate() {
    return await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
      path: 'estimate',
      data: { rule: this.serialize() },
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hub-notification-rule': HubNotificationRuleModel;
  }
}
