import Model, { attr, belongsTo } from '@ember-data/model';

export default class RequestDirectDebitCollectionModel extends Model {
  @attr('string') declare debitorName: string;
  @attr('string') declare debitorIban: string;
  @attr('string') declare uniqueMandateReference: string;
  @attr('string') declare activityTag: string;
  @attr('string') declare note: string;
  @attr('string') declare reference: string;
  // @ts-expect-error
  @attr amount;
  @attr('date') declare scheduledDate: Date;
  @attr('date') declare mandateSignatureDate: Date;

  @belongsTo('request-multi-direct-debit-collection', {
    async: false,
    inverse: 'requestDirectDebitCollections',
  })
  // @ts-expect-error
  requestMultiDirectDebitCollection;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-direct-debit-collection': RequestDirectDebitCollectionModel;
  }
}
