import Model, { attr, belongsTo } from '@ember-data/model';

export default class OrganizationKybDetail extends Model {
  @attr('string') declare annualTurnover: string;
  @attr('number') declare estimatedAnnualTurnover: number;
  @attr('string') declare yearlyTransactionVolume: string;
  @attr('number') declare maxSinglePaymentAmount: number;
  // @ts-expect-error
  @attr natureOfOperations;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: 'kybDetails' }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-kyb-detail': OrganizationKybDetail;
  }
}
