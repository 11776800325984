import Model, { belongsTo } from '@ember-data/model';

export default class Registration extends Model {
  // @ts-expect-error
  @belongsTo('partnerOrganization', { async: true, inverse: 'registration' }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    registration: Registration;
  }
}
