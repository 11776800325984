import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { service, type Registry as Services } from '@ember/service';

import { reads } from 'macro-decorators';

// @ts-expect-error
import RequestMultiTransferTransferValidations from 'qonto/validations/request-multi-transfer-transfer';

// @ts-expect-error
export default class RequestMultiTransferTransferModel extends Model.extend(
  RequestMultiTransferTransferValidations
) {
  // @ts-expect-error
  @hasMany('attachment', { async: false, inverse: null }) attachments;
  // @ts-expect-error
  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  // @ts-expect-error
  @belongsTo('requestMultiTransfer', { async: true, inverse: 'transfers' }) requestMultiTransfer;

  // @ts-expect-error
  @attr({ defaultValue: 'transfer' }) requestType;
  // @ts-expect-error
  @attr beneficiaryIban;
  // @ts-expect-error
  @attr beneficiaryBic;
  // @ts-expect-error
  @attr beneficiaryName;
  // @ts-expect-error
  @attr beneficiaryEmail;
  // @ts-expect-error
  @attr amount;
  // @ts-expect-error
  @attr amountCurrency;
  // @ts-expect-error
  @attr reference;
  // @ts-expect-error
  @attr activityTag;
  // @ts-expect-error
  @attr notifyByEmail;

  // @ts-expect-error
  @reads('savedAttachments.length') attachmentCount;
  // @ts-expect-error
  @reads('requestMultiTransfer.initiator') initiator;
  // @ts-expect-error
  @reads('requestMultiTransfer.approver') approver;
  // @ts-expect-error
  @reads('requestMultiTransfer.status') status;
  // @ts-expect-error
  @reads('requestMultiTransfer.operationType') operationType;
  // @ts-expect-error
  @reads('requestMultiTransfer.note') note;
  // @ts-expect-error
  @reads('requestMultiTransfer.processedAt') processedAt;
  // @ts-expect-error
  @reads('requestMultiTransfer.createdAt') createdAt;
  // @ts-expect-error
  @reads('requestMultiTransfer.once') once;
  // @ts-expect-error
  @reads('requestMultiTransfer.later') later;
  // @ts-expect-error
  @reads('requestMultiTransfer.weekly') weekly;
  // @ts-expect-error
  @reads('requestMultiTransfer.monthly') monthly;
  // @ts-expect-error
  @reads('requestMultiTransfer.hasPastDate') hasPastDate;
  // @ts-expect-error
  @reads('requestMultiTransfer.declinedNote') declinedNote;

  @service declare abilities: Services['abilities'];

  get nonEmptyAttachments() {
    // @ts-expect-error
    return this.attachments.filter(item => get(item, 'file'));
  }

  get attachmentsFiles() {
    // @ts-expect-error
    return this.nonEmptyAttachments.map(item => get(item, 'file'));
  }

  get savedAttachments() {
    // @ts-expect-error
    return this.attachments.filter(item => get(item, 'isNew') === false);
  }

  get hasRequestMultiTransfer() {
    return this.belongsTo('requestMultiTransfer').value();
  }

  get dasherizedRequestType() {
    return 'transfer';
  }

  @dependentKeyCompat
  get shouldValidateActivityTag() {
    return !this.abilities.can('assign category');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-multi-transfer-transfer': RequestMultiTransferTransferModel;
  }
}
