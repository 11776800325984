import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

// @ts-expect-error
import RemindersConfigurationRuleValidations from 'qonto/validations/reminders-configuration';

// @ts-expect-error
export default class RemindersConfigurationModel extends Model.extend(
  RemindersConfigurationRuleValidations
) {
  // @ts-expect-error
  @attr('comma-separated-string') sendTo;
  @attr('boolean') declare copyToSelf: boolean;

  // @ts-expect-error
  @belongsTo('client-hub', { async: false, inverse: null }) client;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  @hasMany('reminders-configuration/rule', {
    async: false,
    inverse: 'remindersConfiguration',
  })
  // @ts-expect-error
  rules;

  get visibleRules() {
    // @ts-expect-error
    return this.rules.filter(rule => !rule.isDeleted);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reminders-configuration': RemindersConfigurationModel;
  }
}
