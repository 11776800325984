import { attr, belongsTo, hasMany } from '@ember-data/model';

import Request from 'qonto/models/request';

export default class RequestMileageModel extends Request {
  // @ts-expect-error
  @attr amount;

  // @ts-expect-error
  @attr createdAt;
  // @ts-expect-error
  @hasMany('attachment', { async: true, inverse: null }) attachments;
  @attr('string', { defaultValue: 'mileage' }) declare requestType: string;
  @attr('string', { defaultValue: 'mileage' }) declare reference: string;
  // @ts-expect-error
  @attr note;
  // @ts-expect-error
  @attr iban;
  // @ts-expect-error
  @attr declinedNote;
  @attr('string', { defaultValue: 'pending' }) declare status: string;

  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr tripDate;
  // @ts-expect-error
  @attr vehicle;
  // @ts-expect-error
  @attr departure;
  // @ts-expect-error
  @attr arrival;
  @attr('boolean') declare roundTrip: boolean;
  @attr('string') declare distanceMeters: string;
  // @ts-expect-error
  @attr mileageCalculation;
  // @ts-expect-error
  @attr requestId;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @hasMany('label', { async: false, inverse: null }) labels;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-mileage': RequestMileageModel;
  }
}
