import Model from '@ember-data/model';

// Should be able to remove this model in ember-data 5
// See https://rfcs.emberjs.com/id/0793-polymporphic-relations-without-inheritance/
export default class SubjectModel extends Model {}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subject: SubjectModel;
  }
}
