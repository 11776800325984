import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { isNil } from 'es-toolkit';
import { equal, lt, reads } from 'macro-decorators';

import { ACCOUNT_STATUS, ACCOUNT_TYPE } from 'qonto/constants/bank-account';

// french CBS accounts used to be on 'QNTOFRP1XXX', now it is 'QNTOFRPAXXX'
const SWIFT_IN_ACCEPTED_BIC = [
  'QNTOFRP1XXX',
  'QNTOFRPAXXX',
  'QNTOITM2XXX',
  'QNTOESB2XXX',
  'QNTODEB2XXX',
];

export default class BankAccount extends Model {
  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];

  /** @type {string|undefined} */
  // @ts-expect-error
  @attr accountNumber;
  /** @type {number|undefined} */
  @attr authorizedBalance: number | undefined;
  /** @type {'current'|'remunerated'} */
  // @ts-expect-error
  @attr accountType;
  /** @type {number|undefined} */
  // @ts-expect-error
  @attr balance;
  /** @type {string|undefined} */
  @attr balanceCurrency: string | undefined;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr bankCode;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr bankName;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr bic;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr branchCode;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr checkDigits;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr controlDigits;
  /** @type {'connect'|'qonto'|'treezor'} */
  // @ts-expect-error
  @attr coreBankingSystem;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr countryCode;
  /** @type {Date} */
  @attr('date') declare createdAt: Date;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr iban;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr ibanPdfUrl;
  /** @type {string|undefined} */
  // @ts-expect-error
  @attr identifier;
  /** @type {BankAccountLogo | undefined} */
  @attr logo: BankAccountLogo | undefined;
  /** @type {string|null} */
  // @ts-expect-error
  @attr name: string | null;
  /** @type {number|undefined} */
  // @ts-expect-error
  @attr processingBalance;
  /** @type {string} */
  // @ts-expect-error
  @attr slug;
  /** @type {string} */
  // @ts-expect-error
  @attr status;
  /** @type {boolean} */
  // @ts-expect-error
  @attr main;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bank-connection-provider', { async: false, inverse: null }) provider;

  idempotencyKey = crypto.randomUUID();

  /** @type {boolean} */
  get hasBalanceInformation() {
    return (
      this.balance !== undefined &&
      this.authorizedBalance !== undefined &&
      this.processingBalance !== undefined
    );
  }

  /** @type {boolean} */
  get isSwiftEnabled() {
    return SWIFT_IN_ACCEPTED_BIC.includes(this.bic);
  }

  /** @type {boolean} */
  get isDebitAccount() {
    let hasBankingDetails = !isNil(this.iban);
    return !this.isRemunerated && (!this.isExternalAccount || hasBankingDetails);
  }

  get safeName() {
    return this.name || this.intl.t('accounts.fallback');
  }

  /** @type {boolean} */
  // @ts-expect-error
  @lt('balance', 0) isLowBalance;

  /** @type {boolean} */
  // @ts-expect-error
  @equal('status', ACCOUNT_STATUS.ACTIVE) isActive;

  /** @type {boolean} */
  // @ts-expect-error
  @equal('status', ACCOUNT_STATUS.PENDING) isPending;

  /** @type {boolean} */
  // @ts-expect-error
  @equal('accountType', ACCOUNT_TYPE.REMUNERATED) isRemunerated: boolean;

  // @ts-expect-error
  @equal('status', ACCOUNT_STATUS.CLOSED) isClosed;

  /** @type {boolean} */
  // @ts-expect-error
  @equal('coreBankingSystem', 'connect') isExternalAccount;

  /** @type {boolean} */
  // @ts-expect-error
  @reads('provider.connection.isFailedConnection') hasFailedConnection;

  /** @type {Date|null} */
  // @ts-expect-error
  @reads('provider.connection.lastSuccessfulSyncAt') lastSyncedAt;

  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.idempotencyKey);
  }

  removeIdempotencyHeader() {
    this.networkManager.removeIdempotencyHeader();
  }

  get ibanObfuscated() {
    let visiblePart = this.iban.substring(0, 4);
    let maskedPart = 'X'.repeat(this.iban.length - 4);

    return visiblePart + maskedPart;
  }

  @waitFor
  async closureRestrictions() {
    return await apiAction(this, { method: 'GET', path: 'closure_restrictions' });
  }

  @waitFor
  async close() {
    let response = await apiAction(this, { method: 'POST', path: 'close' });
    this.store.pushPayload(response);
  }
}

export interface BankAccountLogo {
  small: string;
  medium: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bank-account': BankAccount;
  }
}
