import Model, { attr, hasMany } from '@ember-data/model';

export default class F24ErarioModel extends Model {
  // @ts-expect-error
  @attr officeCode;
  // @ts-expect-error
  @attr deedCode;
  // @ts-expect-error
  @hasMany('f24/erario-tax-item', { async: false, inverse: null }) taxList;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/erario': F24ErarioModel;
  }
}
