import Model, { attr, hasMany } from '@ember-data/model';

export default class F24ImuModel extends Model {
  // @ts-expect-error
  @attr operationCode;
  @attr('number') declare deductionAmount: number;
  // @ts-expect-error
  @hasMany('f24/imu-tax-item', { async: false, inverse: null }) taxList;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/imu': F24ImuModel;
  }
}
