import { attr, belongsTo, hasMany } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

import BaseReceivableInvoiceModel from './receivable-invoice/invoice-base';

/**
 * @typedef FrequencyHash
 * @type {object}
 * @property {integer} value
 * @property { 'weekly' | 'monthly' | 'quarterly' | 'yearly'} recurrence
 */

/**
 * @typedef EmailTemplateHash
 * @type {object}
 * @property {string} emailTitle
 * @property {string} emailBody
 * @property {string} sendTo
 * @property {boolean} copyToSelf
 */

export default class InvoiceSubscriptionModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  // @ts-expect-error
  @attr startDate;
  /** @type {string} */
  // @ts-expect-error
  @attr endDate;
  /** @type {string} */
  // @ts-expect-error
  @attr nextInvoiceDate;
  /** @type {string} */
  // @ts-expect-error
  @attr prevInvoiceDate;
  /** @type {string} */
  // @ts-expect-error
  @attr customerName;
  /** @type {FrequencyHash} */
  // @ts-expect-error
  @attr('hash') frequency;
  /** @type {integer} */
  // @ts-expect-error
  @attr paymentTermsDays;
  /** @type {EmailTemplateHash} */
  // @ts-expect-error
  @attr('hash') emailTemplate;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) declare directDebitEnabled: boolean;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) declare isEinvoice: boolean;
  /** @type {MandateDetailsHash} */
  // @ts-expect-error
  @attr('hash') mandateDetails;

  // @ts-expect-error
  @tracked bic;
  // @ts-expect-error
  @tracked iban;

  sections?: never;

  /** @type {Item} */
  // @ts-expect-error
  @hasMany('receivable-invoice/item', { async: false, inverse: 'invoiceSubscription' }) items;

  /** @type {DirectDebitCollectionMandate} */
  @belongsTo('direct-debit-collection-mandate', { async: true, inverse: null })
  // @ts-expect-error
  directDebitCollectionMandate;

  @waitFor
  async reactivate() {
    let response = await apiAction(this, { method: 'POST', path: 'reactivate' });
    this.store.pushPayload('invoice-subscription', response);
  }

  @waitFor
  async suspend() {
    let response = await apiAction(this, { method: 'POST', path: 'suspend' });
    this.store.pushPayload('invoice-subscription', response);
  }

  // This is used for compatibility with the PDF Preview component
  get issueDate() {
    return this.startDate;
  }

  // This is used for compatibility with the PDF Preview component
  set issueDate(date) {
    this.startDate = date;
  }

  // This is used for compatibility with the PDF Preview component
  get dueDate() {
    return dayjs(this.startDate)
      .add(this.paymentTermsDays, 'days')
      .format(DATE_PICKER_FIELD_FORMAT);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'invoice-subscription': InvoiceSubscriptionModel;
  }
}
