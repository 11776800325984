import Model, { attr, belongsTo } from '@ember-data/model';

export default class DocumentCollectionRequiredDocumentModel extends Model {
  // @ts-expect-error
  @attr docType;
  // @ts-expect-error
  @attr status;

  @belongsTo('document-collection-process', { async: false, inverse: 'requiredDocuments' })
  // @ts-expect-error
  documentCollectionProcess;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'document-collection-required-document': DocumentCollectionRequiredDocumentModel;
  }
}
