import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isNil } from 'es-toolkit';

import CURRENCIES from 'qonto/constants/currencies.ts';
import type ReceivableInvoiceItemModel from 'qonto/models/receivable-invoice/item.ts';
import { limitNumberOfNewLines, round } from 'qonto/utils/receivable-invoicing';

export default class ReceivableInvoiceSectionModel extends Model {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  @attr declare title?: string;
  @attr declare description?: string;
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;

  @tracked
  _isDefault?: boolean;

  get isDefault(): boolean {
    if (isNil(this._isDefault)) {
      let documentSections =
        this.receivableInvoice?.get('sections') ?? this.quote?.get('sections') ?? [];
      return documentSections?.length < 2 && !this.title && !this.description;
    }

    return this._isDefault;
  }

  set isDefault(value: boolean) {
    this._isDefault = value;
  }

  _uuid: string | null = null;

  get uuid() {
    if (!this._uuid) {
      this._uuid = crypto.randomUUID();
    }

    return this._uuid;
  }

  /** @type {Item} */
  // @ts-expect-error
  @hasMany('receivable-invoice/item', { async: false, inverse: 'section' }) items;

  // @ts-expect-error
  @belongsTo('receivableInvoice', { async: true, inverse: 'sections' }) receivableInvoice;
  // @ts-expect-error
  @belongsTo('quote', { async: true, inverse: 'sections' }) quote;

  @action
  // @ts-expect-error
  updateDescription(description) {
    this.description = limitNumberOfNewLines(description, 10);
  }

  clearItemsWithNoId() {
    this.items
      .filter((item: ReceivableInvoiceItemModel) => item.get('id') === null)
      .forEach((item: ReceivableInvoiceItemModel) => item.deleteRecord());
  }

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    return this.items.reduce((total: number, item: ReceivableInvoiceItemModel) => {
      return total + item.preciseDiscountedTotalExcludingVat;
    }, 0);
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat(): string {
    return round(this.preciseTotalExcludingVat, 100).toFixed(2);
  }

  get totalVatWithoutWelfareFund() {
    return this.items.reduce((total: number, item: ReceivableInvoiceItemModel) => {
      return total + item.preciseTotalVat;
    }, 0);
  }

  get vatRates() {
    const itemsVatRates = [
      ...new Set(this.items.map((item: ReceivableInvoiceItemModel) => item?.vatRate)),
    ];
    return itemsVatRates.sort();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/section': ReceivableInvoiceSectionModel;
  }
}
