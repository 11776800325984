import Model, { attr, belongsTo } from '@ember-data/model';

export default class SubscriptionModel extends Model {
  @attr('string') declare code: string;
  // @ts-expect-error
  @attr devices;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: 'subscriptions' }) membership;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: 'subscriptions' }) organization;

  get catCode() {
    return this.code.split('_')[0];
  }

  get notCode() {
    if (!this.code.includes('_')) return this.code;

    let splitCode = this.code.split('_');
    return splitCode.slice(1, splitCode.length).join('_');
  }

  get pushDisabled() {
    return this.notCode === 'monthly_invoice_reporting';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subscription: SubscriptionModel;
  }
}
