import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import dayjs from 'dayjs';

import {
  CERTIFICATION_DURATION_DAYS,
  COMPANY_REPORT_STATUS,
  HIGH_RISK_DUE_DILIGENCE_WEEKS,
  LOW_MEDIUM_RISK_DUE_DILIGENCE_WEEKS,
} from 'qonto/constants/kyc-kyb-update-process';
import { isRiskClassHighOrBanned } from 'qonto/utils/kyc-kyb-update-process';

export default class KycKybUpdateProcess extends Model {
  /** @type {KycKybUpdateMembershipChangeRequest[]} */
  @hasMany('kycKybUpdateMembershipChangeRequest', { async: true, inverse: 'kycKybUpdateProcess' })
  // @ts-expect-error
  kycKybUpdateMembershipChangeRequests;

  /** @type {KycKybUpdateOrganizationChangeRequest | null} */
  @belongsTo('kycKybUpdateOrganizationChangeRequest', {
    async: true,
    inverse: 'kycKybUpdateProcess',
  })
  // @ts-expect-error
  kycKybUpdateOrganizationChangeRequest;

  /** @type {Organization} */
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: 'ongoingKycKybUpdateProcess' }) organization;

  /** @type {File} */
  // @ts-expect-error
  @belongsTo('file', { async: true, inverse: null }) kycKybUpdateKbisFile;

  /** @type {File} */
  // @ts-expect-error
  @belongsTo('file', { async: true, inverse: null }) kycKybUpdateBylawFile;

  /** @type {Date} */
  @attr('date') declare createdAt: Date;

  /** @type {Date} */
  @attr('date') declare dueDiligenceExpiringAt: Date;

  /** @type {boolean} */
  // @ts-expect-error
  @attr legallyRequired;

  /** @type {boolean} */
  // @ts-expect-error
  @attr needsReview;

  /** @type {'created' | 'pending_review' | 'accepted' | 'rejected'} */
  // @ts-expect-error
  @attr status;

  /** @type {'low' | 'medium' | 'high' | 'banned'} */
  // @ts-expect-error
  @attr riskClass;

  /** @type {'unknown' | 'found' | 'not_found' | null} */
  // @ts-expect-error
  @attr({ defaultValue: null }) companyReportStatus;

  get isCompanyReportStatusNull() {
    return this.companyReportStatus === null;
  }

  get isCompanyReportStatusNotFound() {
    return this.companyReportStatus === COMPANY_REPORT_STATUS.NOT_FOUND;
  }

  get isCompanyReportStatusFound() {
    return this.companyReportStatus === COMPANY_REPORT_STATUS.FOUND;
  }

  get isEditable() {
    return this.status === 'created';
  }

  get inReview() {
    return this.status === 'pending_review';
  }

  get isAccepted() {
    return this.status === 'accepted';
  }

  get hasChanges() {
    return (
      this.kycKybUpdateOrganizationChangeRequest?.get('isNew') === false ||
      // @ts-expect-error
      this.kycKybUpdateMembershipChangeRequests.content?.some(it => !it.isNew)
    );
  }

  get isKycKybLegallyRequired() {
    return (
      this.status === 'created' &&
      this.legallyRequired &&
      (this.isCompanyReportStatusNull || this.isCompanyReportStatusNotFound)
    );
  }

  get isDuringCertificationTime() {
    if (!this.isKycKybLegallyRequired) {
      return false;
    }

    let organization = this.organization.get('content');
    let endOfToday = dayjs().endOf('day');

    if (organization.hasRestrictSensitiveActionsFeature && organization.isItalian) {
      let weeksBeforeExpiration = isRiskClassHighOrBanned(this.riskClass)
        ? HIGH_RISK_DUE_DILIGENCE_WEEKS
        : LOW_MEDIUM_RISK_DUE_DILIGENCE_WEEKS;

      let certificationDateStart = dayjs(this.dueDiligenceExpiringAt).subtract(
        weeksBeforeExpiration,
        'weeks'
      );

      return (
        endOfToday.isAfter(certificationDateStart) &&
        // @ts-expect-error
        endOfToday.isSameOrBefore(dayjs(this.dueDiligenceExpiringAt))
      );
    } else {
      let certificationDate = dayjs(this.createdAt).add(CERTIFICATION_DURATION_DAYS, 'day');
      return certificationDate.isAfter(endOfToday);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'kyc-kyb-update-process': KycKybUpdateProcess;
  }
}
