import Model, { attr, belongsTo } from '@ember-data/model';

// available docTypes = [
//   'kbis',
//   'bylaw',
//   'poa',
//   'poi',
//   'contract',
//   'admin_power',
//   'employee_power',
//   'register_power'
// ]

export default class Document extends Model {
  @attr('string') declare subjectId: string;
  @attr('string') declare subjectType: string;
  @attr('string') declare slug: string;
  @attr('string') declare docType: string;
  @attr('string') declare docSubtype: string;
  @attr('string') declare status: string;
  // @ts-expect-error
  @attr('files') files;

  // @ts-expect-error
  @belongsTo('invite', { async: true, inverse: 'documents' }) invite;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: 'documents' }) membership;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: 'documents' }) organization;
  // @ts-expect-error
  @belongsTo('stakeholder', { async: true, inverse: 'documents' }) stakeholder;

  get file() {
    return this.files?.[0];
  }

  // @ts-expect-error
  set(key, file) {
    if (Array.isArray(this.files)) {
      this.set('files.0', file);
    } else {
      this.set('files', [file]);
    }
    return file;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    document: Document;
  }
}
