import Model, { attr, belongsTo } from '@ember-data/model';
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';

// @ts-expect-error
import { CLIENT_KINDS } from 'qonto/constants/clients';
import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
  // @ts-expect-error
} from 'qonto/constants/countries-to-locales';
import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import ClientValidations from 'qonto/validations/client';

/**
 * @typedef CustomerAddressHash
 * @type {object}
 * @property {string} streetAddress
 * @property {string} zipCode
 * @property {string} city
 * @property {string} provinceCode - only when country is Italy
 * @property {string} countryCode
 */

// @ts-expect-error
export function getDefaultCustomerValues(ownerOrOwned) {
  let owner = getOwner(ownerOrOwned) || ownerOrOwned;
  let organizationManager = owner.lookup('service:organization-manager');
  let organization = organizationManager.organization;
  let countryCode = organization.legalCountry;
  return {
    countryCode,
    billingAddress: {
      countryCode,
    },
    locale: getDefaultLocale(countryCode),
    kind: CLIENT_KINDS.COMPANY,
    currency: CURRENCIES.default,
    organization,
  };
}

// @ts-expect-error
export function getDefaultLocale(countryCode) {
  let customerCountryCode = countryCode?.toLowerCase();

  if (!customerCountryCode) {
    return DEFAULT_LOCALE_CODE;
  }

  let customerLocaleCode = COUNTRIES_TO_LOCALES_ARRAY.find(
    // @ts-expect-error
    ({ country_code }) => country_code === customerCountryCode
  )?.code;

  return customerLocaleCode || DEFAULT_LOCALE_CODE;
}

type BillingAddress = {
  streetAddress?: string;
  city?: string;
  zipCode?: string;
  provinceCode?: string;
  countryCode?: string;
};

type DeliveryAddress = {
  streetAddress?: string;
  city?: string;
  zipCode?: string;
  provinceCode?: string;
  countryCode?: string;
};

// @ts-expect-error
export default class CustomerModel extends Model.extend(ClientValidations) {
  @service declare intl: Services['intl'];

  @attr('string', { defaultValue: CLIENT_KINDS.COMPANY }) declare kind: string;
  @attr('string') declare name: string; // when kind is company
  @attr('string') declare firstName: string; // when kind is individual or freelancer
  @attr('string') declare lastName: string; // when kind is individual or freelancer
  @attr('string') declare email: string;
  /** @deprecated use billingAddress instead */
  @attr('string') declare address: string;
  /** @deprecated use billingAddress instead */
  @attr('string') declare zipCode: string;
  /** @deprecated use billingAddress instead */
  @attr('string') declare city: string;
  /** @deprecated use billingAddress instead */
  @attr('string') declare provinceCode: string; // only when country is Italy
  /** @deprecated  use billingAddress instead */
  @attr('string') declare countryCode: string;
  @attr('hash') declare billingAddress: BillingAddress;
  @attr('hash') declare deliveryAddress: DeliveryAddress;
  @attr('string') declare locale: string;
  @attr('string') declare tinNumber: string;
  @attr('string') declare vatNumber: string;
  @attr('string') declare recipientCode: string;
  @attr('string') declare updatedAt: string;
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  get localeObject() {
    // @ts-expect-error
    return this.intl.getLocales.find(({ code }) => code === this.locale?.toLowerCase());
  }

  set localeObject({ code }) {
    this.locale = code;
  }

  get isCompany() {
    return this.kind === CLIENT_KINDS.COMPANY;
  }

  get isFreelancer() {
    return this.kind === CLIENT_KINDS.FREELANCER;
  }

  get isIndividual() {
    return this.kind === CLIENT_KINDS.INDIVIDUAL;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    customer: CustomerModel;
  }
}
