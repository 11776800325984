import Model, { attr, belongsTo } from '@ember-data/model';

export default class SearchPresetModel extends Model {
  // @ts-expect-error
  @attr name;
  // @ts-expect-error
  @attr position;
  // @ts-expect-error
  @attr matchesCount;
  // @ts-expect-error
  @attr query;
  // @ts-expect-error
  @attr type;
  // @ts-expect-error
  @attr createdAt;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'search-preset': SearchPresetModel;
  }
}
