import { attr, belongsTo, hasMany } from '@ember-data/model';

import Assignable from 'qonto/models/assignable';

export default class TeamModel extends Assignable {
  // @ts-expect-error
  @attr name;
  @attr('number') declare activeMembershipsCount: number;
  @attr('number') declare membershipsCount: number;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // active team managers
  // @ts-expect-error
  @hasMany('membership', { async: false, inverse: null }) teamManagers;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    team: TeamModel;
  }
}
