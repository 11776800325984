import { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { multiply, round } from 'qonto/utils/receivable-invoicing';

import BaseReceivableInvoiceModel from './receivable-invoice/invoice-base';

export default class ReceivableCreditNoteModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  // @ts-expect-error
  @attr number;
  /** @type {string} YYYY-MM-DD */
  @attr('string', { defaultValue: () => dayjs().format(DATE_PICKER_FIELD_FORMAT) })
  declare issueDate: string;
  /** @type {string} */
  // @ts-expect-error
  @attr reason;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  // @ts-expect-error
  @attr einvoicingStatus;

  /** @type {ReceivableInvoice} */
  @belongsTo('receivable-invoice', { async: true, inverse: 'receivableCreditNotes' })
  // @ts-expect-error
  receivableInvoice;
  /** @type {Atttachment} */
  // @ts-expect-error
  @belongsTo('attachment', { async: false, inverse: null }) attachment;

  /** @type {WelfareFund} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Item} */
  // @ts-expect-error
  @hasMany('receivable-invoice/item', { async: false, inverse: null }) items;

  @service declare networkManager: Services['networkManager'];

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  get totalAmount() {
    let totalAmount =
      // @ts-expect-error
      parseFloat(this.preciseTotalVat) +
      parseFloat(this.preciseDiscountedTotalExcludingVat) +
      parseFloat(this.preciseWelfareFundAmount) +
      parseFloat(this.preciseWithholdingTaxAmount);
    return totalAmount.toFixed(2);
  }

  get preciseWithholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return String(
        multiply(
          parseFloat(this.preciseDiscountedTotalExcludingVat) +
            parseFloat(this.preciseWelfareFundAmount),
          // @ts-expect-error
          parseFloat(-this.withholdingTax.rate)
        )
      );

    return String(
      multiply(
        parseFloat(this.preciseDiscountedTotalExcludingVat),
        // @ts-expect-error
        parseFloat(-this.withholdingTax.rate)
      )
    );
  }

  get withholdingTaxAmount() {
    return round(this.preciseWithholdingTaxAmount, 100).toFixed(2);
  }

  get displayEachVatSubtotals() {
    return false;
  }

  get pdfUrl() {
    // @ts-expect-error
    return this.store.adapterFor('receivable-credit-note').urlForPdf(this.id);
  }

  get depositAmount() {
    // @ts-expect-error
    return this.get('receivableInvoice.depositAmount');
  }

  get totalAmountDue() {
    if (this.depositAmount) {
      // @ts-expect-error
      let totalAmountDue = parseFloat(this.totalAmount) + parseFloat(this.depositAmount);
      return totalAmountDue.toFixed(2);
    }
  }

  @waitFor
  async setPdfPreviewIframeUrl() {
    if (!this.pdfUrl) return;

    let reader = new FileReader();
    let handler = () => {
      // @ts-expect-error
      this.pdfPreviewIframeUrl = reader.result;
    };

    try {
      let response = await this.networkManager.rawRequest(this.pdfUrl, { method: 'GET' });
      let blob = await response.blob();

      reader.addEventListener('load', handler);
      reader.readAsDataURL(blob);
    } catch {
      this.pdfPreviewIframeUrl = null;
      // @ts-expect-error
      reader.removeEventListener(handler);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-credit-note': ReceivableCreditNoteModel;
  }
}
