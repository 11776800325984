// @ts-nocheck
import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class CardAcquirerPayoutModel extends Subject {
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @attr('number') declare amount: number;
  @attr('string') declare amountCurrency: string;
  @attr('string') declare reference: string;
  @attr('string') declare status: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'card-acquirer-payout': CardAcquirerPayoutModel;
  }
}
