import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
import type CustomerModel from 'qonto/models/customer.ts';
import { NetworkManagerError } from 'qonto/services/network-manager';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import { multiply, round } from 'qonto/utils/receivable-invoicing';

/**
 * @typedef DiscountHash
 * @type {object}
 * @property {string} amount
 * @property {string} value
 * @property {string} type
 */

export default class BaseInvoicingDocumentModel extends Model {
  @service declare networkManager: Services['networkManager'];

  /** @type {DiscountHash} */
  // @ts-expect-error
  @attr('hash') discount;
  /** @type {string} */
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;
  /** @type {string} */
  // @ts-expect-error
  @attr locale;
  /** @type {string} */
  // @ts-expect-error
  @attr beneficiaryName;
  /** @type {string} */
  // @ts-expect-error
  @attr contactEmail;
  /** @type {string} */
  // @ts-expect-error
  @attr termsAndConditions;
  /** @type {string} */
  // @ts-expect-error
  @attr stampDutyAmount;
  /** @type {string} */
  // @ts-expect-error
  @attr header;
  /** @type {string} */
  // @ts-expect-error
  @attr footer;
  /** @type {string} */
  // @ts-expect-error
  @attr amountDue;
  /** @type {Date} */
  // @ts-expect-error
  @attr updatedAt;
  /** @type {Date} */
  // @ts-expect-error
  @attr createdAt;
  /** @type {string} */
  // @ts-expect-error
  @attr status;
  @attr declare saveCustomMessages: boolean;

  /** @type {WelfareFund} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;
  @belongsTo('client-hub', { async: true, inverse: null })
  // @ts-expect-error
  declare customer: AsyncBelongsTo<CustomerModel>;
  /** @type {Organization} */
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @belongsTo('receivable-invoices-upload', { inverse: null, async: false })
  // @ts-expect-error
  receivableInvoicingUpload;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  @waitFor
  // @ts-expect-error
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      // @ts-expect-error
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  clearItemsWithNoId() {
    // @ts-expect-error
    if (this.sections?.length) {
      // @ts-expect-error
      this.sections.forEach(section => section.clearItemsWithNoId());
      // @ts-expect-error
      this.sections
        // @ts-expect-error
        .filter(section => section.get('id') === null)
        // @ts-expect-error
        .forEach(section => section.deleteRecord());
    } else {
      // @ts-expect-error
      this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
    }
  }

  // @ts-expect-error
  _handleError(error) {
    if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
      let errors = errorsArrayToHash(error.errors, { useErrorCode: true });
      this.networkManager.errorModelInjector(this, errors);
    }

    if (error.isAdapterError || error instanceof NetworkManagerError) {
      this._assignRelationshipErrors(error);
    }

    throw error;
  }

  // @ts-expect-error
  _assignRelationshipErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    // TODO: Remove this when `feature--boolean-ar-advanced-customization` is removed
    let parsedItemsErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    // reduce
    // [
    //   { attribute: "sections/0/items/0/title", message: "required" }
    //   { attribute: "sections/0/items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: {
    //     items: {
    //       0: { "title": ["required"] }
    //       1: { "title": ["required"] }
    //     }
    //   }
    // }
    // then assign to sections
    let parsedSectionsErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, ...other] = error.attribute.split('/');
      let attribute = other.join('/');

      if (invoiceAttr === 'sections') {
        errorsForItems[index] = errorsForItems[index] || {};
        let [invoiceAttr, itemIndex, itemAttribute] = attribute.split('/');

        if (invoiceAttr === 'items') {
          error = { attribute: itemAttribute, message: error.message };
          errorsForItems[index]['items'] = errorsForItems[index]['items'] || {};
          errorsForItems[index]['items'][itemIndex] =
            errorsForItems[index]['items'][itemIndex] || {};
          errorsForItems[index]['items'][itemIndex][itemAttribute] =
            errorsForItems[index]['items'][itemIndex][itemAttribute] || [];
          errorsForItems[index]['items'][itemIndex][itemAttribute].push(error.message);
        } else {
          error = { attribute, message: error.message };
          errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
          errorsForItems[index][attribute].push(error.message);
        }
      }
      return errorsForItems;
    }, {});

    let parsedRelationshipErrors = this.errors.reduce(
      (errorsForRelationships, relationshipError) => {
        let [invoiceAttr, attribute] = relationshipError.attribute.split('/');
        if (
          invoiceAttr === 'payment' ||
          invoiceAttr === 'welfareFund' ||
          invoiceAttr === 'withholdingTax'
        ) {
          errorsForRelationships[invoiceAttr] = errorsForRelationships[invoiceAttr] || {};
          errorsForRelationships[invoiceAttr][attribute] =
            errorsForRelationships[invoiceAttr][attribute] || [];
          errorsForRelationships[invoiceAttr][attribute].push(relationshipError.message);
        }
        return errorsForRelationships;
      },
      {}
    );

    // TODO: Remove this when `feature--boolean-ar-advanced-customization` is removed
    Object.entries(parsedItemsErrors).forEach(([index, parsedErrorsForItem]) => {
      // @ts-expect-error
      this.networkManager.errorModelInjector(this.items.at(index), parsedErrorsForItem, error);
    });

    // @ts-expect-error
    if (!isEmpty(this.sections)) {
      Object.entries<Record<string, unknown>>(parsedSectionsErrors).forEach(
        ([index, parsedErrorsForSection]) => {
          const { items, ...others } = parsedErrorsForSection;
          // @ts-expect-error
          let section = this.sections.at(index);
          if (section) {
            // @ts-expect-error
            this.networkManager.errorModelInjector(section, others, error);
          }

          // @ts-expect-error
          if (this.sections[index]) {
            // @ts-expect-error
            Object.entries(items).forEach(([itemIndex, parsedErrorsForItem]) => {
              this.networkManager.errorModelInjector(
                // @ts-expect-error
                this.sections[index].items[itemIndex],
                parsedErrorsForItem,
                // @ts-expect-error
                error
              );
            });
          }
        }
      );
    }

    Object.entries(parsedRelationshipErrors).forEach(([attribute, parsedRelationshipError]) => {
      if (attribute === 'payment') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.payment, parsedRelationshipError, error);
      }
      if (attribute === 'welfareFund') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.welfareFund, parsedRelationshipError, error);
      }
      if (attribute === 'withholdingTax') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.withholdingTax, parsedRelationshipError, error);
      }
    });
  }

  // BE dive-in calculations https://gitlab.qonto.co/tech/divein/blob/master/text/2024/12/2024-12-04-BACKEND-global-discount.md#calculations

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    // @ts-expect-error
    if (this.sections?.length) {
      // @ts-expect-error
      return this.sections.reduce((total, section) => {
        return parseFloat(total) + parseFloat(section.preciseTotalExcludingVat);
      }, 0);
    }
    // @ts-expect-error
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseDiscountedTotalExcludingVat);
    }, 0);
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat() {
    return round(this.preciseTotalExcludingVat, 100).toFixed(2);
  }

  get preciseDiscountedTotalExcludingVat() {
    if (this.discount?.type === 'percentage') {
      return parseFloat(this.preciseTotalExcludingVat) - this.precisePercentageDiscountAmount;
    }

    return this.discount?.type === 'absolute'
      ? parseFloat(this.preciseTotalExcludingVat) - this.preciseTotalDiscount
      : this.preciseTotalExcludingVat;
  }

  get discountedTotalExcludingVat() {
    return round(this.preciseDiscountedTotalExcludingVat, 100).toFixed(2);
  }

  get totalAmount() {
    let totalAmount =
      // @ts-expect-error
      parseFloat(this.preciseTotalVat) +
      parseFloat(this.preciseDiscountedTotalExcludingVat) +
      parseFloat(this.preciseWelfareFundAmount) -
      parseFloat(this.preciseWithholdingTaxAmount);

    return totalAmount.toFixed(2);
  }

  get totalAmountDue() {
    // @ts-expect-error
    if (this.depositAmount) {
      // @ts-expect-error
      let totalAmountDue = parseFloat(this.totalAmount) - parseFloat(this.depositAmount);
      return totalAmountDue.toFixed(2);
    }
  }

  get preciseTotalVatWithoutWelfareFund() {
    let totalVatWithoutWelfareFund = 0;
    // if there is a discount, the total vat is calculated after applying the document discount on each item

    // if more than one vat rate is present, the vat totals are summed and then rounded
    if (this.discount?.value && this.vatRates.length > 1) {
      totalVatWithoutWelfareFund = this.calculateVatSubtotals.reduce((total, vatSubtotal) => {
        // @ts-expect-error
        return parseFloat(total) + parseFloat(vatSubtotal.preciseTotalVat);
      }, 0);

      // if only one vat rate is present but multiple items, the vat totals are rounded and then summed
    } else if (this.discount?.value && this.vatRates.length > 0) {
      totalVatWithoutWelfareFund = this.calculateVatSubtotals.reduce((total, vatSubtotal) => {
        // @ts-expect-error
        return parseFloat(total) + parseFloat(vatSubtotal.totalVat);
      }, 0);
    } else {
      // if no discount the items total vat are precised and can be used
      // @ts-expect-error
      if (this.sections?.length) {
        // @ts-expect-error
        totalVatWithoutWelfareFund = this.sections.reduce((total, section) => {
          return parseFloat(total) + parseFloat(section.totalVatWithoutWelfareFund);
        }, 0);
      } else {
        // @ts-expect-error
        totalVatWithoutWelfareFund = this.items.reduce((total, item) => {
          return parseFloat(total) + parseFloat(item.preciseTotalVat);
        }, 0);
      }
    }

    return totalVatWithoutWelfareFund;
  }

  get totalVatWithoutWelfareFund() {
    return round(this.preciseTotalVatWithoutWelfareFund, 100).toFixed(2);
  }

  get preciseTotalVat() {
    return (
      // @ts-expect-error
      parseFloat(this.preciseTotalVatWithoutWelfareFund) +
      parseFloat(this.preciseWelfareFundVatAmount)
    );
  }

  get totalVat() {
    return round(this.preciseTotalVat, 100).toFixed(2);
  }

  get preciseWelfareFundVatAmount() {
    return this.welfareFund?.rate
      ? String(
          multiply(
            // @ts-expect-error
            parseFloat(this.preciseTotalVatWithoutWelfareFund),
            parseFloat(this.welfareFund.rate)
          )
        )
      : '0.00';
  }

  get welfareFundVatAmount() {
    return round(this.preciseWelfareFundVatAmount, 100).toFixed(2);
  }

  get preciseWelfareFundAmount() {
    return this.welfareFund?.rate
      ? String(
          multiply(
            parseFloat(this.preciseDiscountedTotalExcludingVat),
            parseFloat(this.welfareFund.rate)
          )
        )
      : '0.00';
  }

  get welfareFundAmount() {
    return round(this.preciseWelfareFundAmount, 100).toFixed(2);
  }

  get preciseWithholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return String(
        multiply(
          parseFloat(this.preciseDiscountedTotalExcludingVat) +
            parseFloat(this.preciseWelfareFundAmount),
          parseFloat(this.withholdingTax.rate)
        )
      );

    return String(
      multiply(
        parseFloat(this.preciseDiscountedTotalExcludingVat),
        parseFloat(this.withholdingTax.rate)
      )
    );
  }

  get withholdingTaxAmount() {
    return round(this.preciseWithholdingTaxAmount, 100).toFixed(2);
  }

  // Document discount calculations
  get precisePercentageDiscountAmount() {
    return this.preciseTotalExcludingVat && this.discount?.value
      ? multiply(this.discount.value, this.preciseTotalExcludingVat)
      : 0;
  }

  get percentageDiscountAmount() {
    return round(this.precisePercentageDiscountAmount, 100).toFixed(2);
  }

  get preciseTotalDiscount() {
    if (this.discount?.type === 'absolute') {
      let sign = Math.sign(parseFloat(this.totalExcludingVat));

      return this.discount.value * sign;
    } else if (this.discount?.type === 'percentage') {
      return this.precisePercentageDiscountAmount;
    } else {
      return 0;
    }
  }

  get totalDiscount() {
    return round(this.preciseTotalDiscount, 100).toFixed(2);
  }

  // Subtotals for each VAT rate

  // display subtotals for each VAT rate if more than one rate is present
  get displayEachVatSubtotals() {
    return this.vatRates.length > 1;
  }

  // all the unique different vat rates used in the document saved in an array
  get vatRates() {
    // @ts-expect-error
    if (this.sections?.length) {
      return [
        ...new Set(
          // @ts-expect-error
          this.sections.reduce((vatRates, section) => {
            return [...vatRates, ...section.vatRates];
          }, [])
        ),
      ].sort();
    }
    // @ts-expect-error
    return [...new Set(this.items.map(item => item?.vatRate))].sort();
  }

  // an array of objects for each unique different vat rates with their calculated totals rounded for the UI
  get vatSubtotals() {
    // @ts-expect-error
    let vatSubtotals = [];
    if (this.displayEachVatSubtotals) {
      vatSubtotals = this.calculateVatSubtotals.map(subtotal => {
        return {
          rate: subtotal.rate,
          vatTotal: subtotal.preciseTotalVat.toFixed(2),
          totalExcludingVat: subtotal.preciseTotalExcludingVat.toFixed(2),
        };
      });
    }

    // @ts-expect-error
    return vatSubtotals;
  }

  // an array of objects for each unique different vat rates with their calculated totals precise and not rounded
  get calculateVatSubtotals() {
    let vatSubtotals = [];

    if (this.vatRates.length > 0) {
      for (let index = 0; index < this.vatRates.length; index++) {
        let rate = this.vatRates[index];

        let preciseTotalExcludingVat = 0;
        let preciseTotalVat = 0;
        let totalExcludingVat = 0;
        let totalVat = 0;

        // @ts-expect-error
        if (this.sections?.length) {
          // @ts-expect-error
          for (let section of this.sections) {
            for (let item of section.items) {
              if (rate === item?.vatRate) {
                // in the case of a discount on the document level, each item needs to have a part of the document discount applied
                if (this.discount?.value) {
                  // discounted total excluding vat of the item divided by total excluding vat of the document
                  let itemTotalDocumentTotalRatio =
                    item.preciseDiscountedTotalExcludingVat / this.preciseTotalExcludingVat;

                  // document discount * itemTotalDocumentTotalRatio = the discount applied on the item
                  let preciseItemDocumentDiscount = multiply(
                    this.preciseTotalDiscount,
                    itemTotalDocumentTotalRatio
                  );

                  // document discount * itemTotalDocumentTotalRatio * item vat rate = vat amount of the discount applied on the item
                  let vatAmountItemDocumentDiscount = multiply(
                    preciseItemDocumentDiscount,
                    item.vatRate as number
                  );

                  let itemPreciseTotalVat = item.preciseTotalVat - vatAmountItemDocumentDiscount;

                  // BE sums the rounded vat totals of each item
                  let itemTotalVat = round(itemPreciseTotalVat, 100).toFixed(2);

                  let itemPreciseTotalExcludingVat =
                    item.preciseDiscountedTotalExcludingVat - preciseItemDocumentDiscount;

                  let itemTotalExcludingVat = round(itemPreciseTotalExcludingVat, 100).toFixed(2);

                  preciseTotalVat += itemPreciseTotalVat;

                  preciseTotalExcludingVat += itemPreciseTotalExcludingVat;

                  totalExcludingVat += parseFloat(itemTotalExcludingVat);

                  totalVat += parseFloat(itemTotalVat);
                } else {
                  preciseTotalVat += parseFloat(item.preciseTotalVat);
                  preciseTotalExcludingVat += parseFloat(item.preciseDiscountedTotalExcludingVat);

                  totalExcludingVat += parseFloat(item.preciseTotalVat);

                  totalVat += parseFloat(item.preciseDiscountedTotalExcludingVat);
                }
              }
            }
          }
        } else {
          // @ts-expect-error
          for (let item of this.items) {
            if (rate === item?.vatRate) {
              // in the case of a discount on the document level, each item needs to have a part of the document discount applied
              if (this.discount?.value) {
                // discounted total excluding vat of the item divided by total excluding vat of the document
                let itemTotalDocumentTotalRatio =
                  item.preciseDiscountedTotalExcludingVat / this.preciseTotalExcludingVat;

                //  document discount * itemTotalDocumentTotalRatio = the discount applied on the item
                let preciseItemDocumentDiscount = multiply(
                  this.preciseTotalDiscount,
                  itemTotalDocumentTotalRatio
                );

                // document discount * itemTotalDocumentTotalRatio * item vat rate = vat amount of the discount applied on the item
                let vatAmountItemDocumentDiscount = multiply(
                  preciseItemDocumentDiscount,
                  item.vatRate as number
                );

                let itemPreciseTotalVat = item.preciseTotalVat - vatAmountItemDocumentDiscount;

                // BE sums the rounded vat totals of each item
                let itemTotalVat = round(itemPreciseTotalVat, 100).toFixed(2);

                let itemPreciseTotalExcludingVat =
                  item.preciseDiscountedTotalExcludingVat - preciseItemDocumentDiscount;

                let itemTotalExcludingVat = round(itemPreciseTotalExcludingVat, 100).toFixed(2);

                preciseTotalVat += itemPreciseTotalVat;

                preciseTotalExcludingVat += itemPreciseTotalExcludingVat;

                totalExcludingVat += parseFloat(itemTotalExcludingVat);

                totalVat += parseFloat(itemTotalVat);
              } else {
                preciseTotalVat += parseFloat(item.preciseTotalVat);
                preciseTotalExcludingVat += parseFloat(item.preciseDiscountedTotalExcludingVat);

                totalExcludingVat += parseFloat(item.preciseTotalVat);

                totalVat += parseFloat(item.preciseDiscountedTotalExcludingVat);
              }
            }
          }
        }

        vatSubtotals.push({
          rate,
          preciseTotalVat,
          preciseTotalExcludingVat,
          totalExcludingVat,
          totalVat,
        });
      }
    }

    return vatSubtotals;
  }

  // discounted total excluding vat divided by total excluding vat
  get totalExcludingVatDiscountRatio() {
    return (
      parseFloat(this.preciseDiscountedTotalExcludingVat) /
      parseFloat(this.preciseTotalExcludingVat)
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/base': BaseInvoicingDocumentModel;
  }
}
