import Model, { attr } from '@ember-data/model';

export default class F24V2PayerAgentModel extends Model {
  @attr('string') declare fiscalCode: string;
  @attr('string') declare type: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/payer-agent': F24V2PayerAgentModel;
  }
}
