import Model, { attr, belongsTo } from '@ember-data/model';

export default class UserActionModel extends Model {
  // we need to store an id that is unique for a user_action with membership id. However, the API just provides the user_action id, so we override it in the serializer, and create a copy to keep the PATCH working
  // @ts-expect-error
  @attr originalId;

  // @ts-expect-error
  @attr key;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr type;
  // @ts-expect-error
  @attr descriptionText;
  // @ts-expect-error
  @attr ctaText;
  // @ts-expect-error
  @attr titleText;
  // @ts-expect-error
  @attr illustrationUrl;
  @attr('boolean') declare isBadgeRequired: boolean;
  @attr('boolean') declare isPrimaryCard: boolean;
  // @ts-expect-error
  @attr hiddenBy;
  // @ts-expect-error
  @attr blockedBy;

  // @ts-expect-error
  @attr('hash') deeplink;

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) membership;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-action': UserActionModel;
  }
}
