import Model, { attr, belongsTo } from '@ember-data/model';

import { bool } from 'macro-decorators';

import { MEMBER_STATUS } from 'qonto/constants/membership';
import titleize from 'qonto/utils/titleize';

export default class SuggestedInvitationModel extends Model {
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare email: string;
  @attr('string') declare avatarUrl: string;
  @attr('string') declare provider: string; // nullable for old suggested
  @attr('string') declare department: string; // nullable for old suggested
  @attr('string') declare jobTitle: string; // nullable for old suggested

  // @ts-expect-error
  @bool('provider') importedFromHR;

  // Useful for backward compatibility with old suggested members.
  // When the previous implementation is deleted, these fields will also be deleted
  status = MEMBER_STATUS.INVITABLE;
  invitable = true;
  suggested = true;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: 'suggested-invitation' }) organization;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();

    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }

    if (firstName) {
      return titleize(firstName);
    }

    if (lastName) {
      return titleize(lastName);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'suggested-invitation': SuggestedInvitationModel;
  }
}
