import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class F24PaymentModel extends Subject {
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  @attr('boolean') declare isProxied: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24-payment': F24PaymentModel;
  }
}
