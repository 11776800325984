import Model, { attr } from '@ember-data/model';

export default class F24RegioniTaxItemModel extends Model {
  // @ts-expect-error
  @attr taxCode;
  // @ts-expect-error
  @attr regionCode;
  // @ts-expect-error
  @attr referenceMonth;
  // @ts-expect-error
  @attr referenceYear;
  @attr('number') declare taxAmount: number;
  @attr('number') declare compensationAmount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/regioni-tax-item': F24RegioniTaxItemModel;
  }
}
