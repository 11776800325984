import Model, { attr, belongsTo } from '@ember-data/model';

export default class InvoiceModel extends Model {
  // @ts-expect-error
  @attr() amount;
  // @ts-expect-error
  @attr() totalAmount;
  // @ts-expect-error
  @attr() vatAmount;
  @attr('string') declare currency: string;
  @attr('string') declare pdfUrl: string;
  @attr('string') declare slug: string;
  @attr('date') declare fromDate: Date;
  @attr('date') declare toDate: Date;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invoice: InvoiceModel;
  }
}
