import Model, { attr, belongsTo } from '@ember-data/model';

export default class F24PayerModel extends Model {
  // @ts-expect-error
  @attr firstName;
  // @ts-expect-error
  @attr lastName;
  // @ts-expect-error
  @attr businessName;
  @attr('boolean') declare isDifferentYear: boolean;
  // @ts-expect-error
  @attr fiscalCode;
  // @ts-expect-error
  @attr birthDate;
  // @ts-expect-error
  @attr birthPlace;
  // @ts-expect-error
  @attr birthProvince;
  // @ts-expect-error
  @attr sex;
  // @ts-expect-error
  @attr otherRelatedFiscalCode;
  // @ts-expect-error
  @belongsTo('f24/address', { async: false, inverse: null }) address;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/payer': F24PayerModel;
  }
}
