import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import { DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS } from 'qonto/constants/direct-debit-collections';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

export default class DirectDebitCollectionActivationModel extends Model {
  @attr('string') declare status: string;
  @attr('string') declare collectionLimit: string;
  @attr('boolean') declare hasGuarding: boolean;
  @attr('string') declare guardingDepositPercentage: string;
  @attr('string') declare cid: string;
  @attr('boolean') declare cidRequested: boolean;
  @attr('boolean') declare termsOfServiceAccepted: boolean;

  @service declare sentry: Services['sentry'];
  @service declare organizationManager: Services['organizationManager'];

  @waitFor
  async fetchData() {
    try {
      let data = await apiAction(this, {
        method: 'GET',
      });

      this.status = data.status;
      this.collectionLimit = data.collection_limit;
      this.guardingDepositPercentage = data.guarding_deposit_percentage;
      this.hasGuarding = data.has_guarding;

      return this;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  @waitFor
  async fetchCid() {
    let data = await apiAction(this, {
      method: 'GET',
      path: 'cid',
    });

    this.cid = data.cid;
    return this;
  }

  @waitFor
  async activate() {
    let data = {
      cid_requested: this.cidRequested,
      tos_accepted: this.termsOfServiceAccepted,
      cid: this.cid,
    };

    if (this.cidRequested) {
      // @ts-expect-error
      delete data.cid;
    }
    await apiAction(this, {
      method: 'POST',
      data,
    });
  }

  get isEligible() {
    return this.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.ELIGIBLE;
  }

  get isActivated() {
    return this.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.ACTIVATED;
  }

  get isActivatedNotEligible() {
    return this.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.ACTIVATED_NOT_ELIGIBLE;
  }

  get hasPendingCreditorIdentifierVerification() {
    return (
      this.status ===
      DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.PENDING_CREDITOR_IDENTIFIER_VERIFICATION
    );
  }

  get hasPendingCreditorIdentifierCreation() {
    return (
      this.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.PENDING_CREDITOR_IDENTIFIER_CREATION
    );
  }

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit-collection-activation': DirectDebitCollectionActivationModel;
  }
}
