import Model, { belongsTo, hasMany } from '@ember-data/model';

export default class F24TaxInformationModel extends Model {
  // @ts-expect-error
  @belongsTo('f24/erario', { async: false, inverse: null }) erario;
  // @ts-expect-error
  @belongsTo('f24/imu', { async: false, inverse: null }) imu;
  // @ts-expect-error
  @belongsTo('f24/others-tax', { async: false, inverse: null }) others;
  // @ts-expect-error
  @hasMany('f24/inps-tax-item', { async: false, inverse: null }) inpsTaxList;
  // @ts-expect-error
  @hasMany('f24/inail-tax-item', { async: false, inverse: null }) inailTaxList;
  // @ts-expect-error
  @hasMany('f24/regioni-tax-item', { async: false, inverse: null }) regioniTaxList;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/tax-information': F24TaxInformationModel;
  }
}
