import Model, { attr } from '@ember-data/model';

export default class SubscriptionsFeatureModel extends Model {
  @attr('string') declare code: string;
  // @ts-expect-error
  @attr limit;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscriptions-feature': SubscriptionsFeatureModel;
  }
}
