import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
// @ts-expect-error
import { UserPasswordValidations } from 'qonto/validations/user';

// @ts-expect-error
export default class UsersPasswordModel extends Model.extend(UserPasswordValidations) {
  @service declare networkManager: Services['networkManager'];

  @attr('string') declare resetPasswordToken: string;
  @attr('string') declare email: string;
  @attr('string') declare currentPassword: string;
  @attr('string') declare password: string;
  // @ts-expect-error
  @attr mfaEnabled;
  // @ts-expect-error
  @attr mfaMethod;

  // X-Connect users does not have a password. Set this to false when it's the case to be able to
  // distinguish when transitioning to the email/pwd flow.
  @tracked hasPassword = true;

  @waitFor
  async forgotPassword() {
    let data = { user: { email: this.email } };
    return await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
      path: 'forgot',
      data,
    });
  }

  @waitFor
  async resetPassword() {
    let data = {
      user: {
        reset_password_token: this.resetPasswordToken,
        password: this.password,
      },
    };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'reset',
        data,
      });
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'users/password': UsersPasswordModel;
  }
}
