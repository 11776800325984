import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

// @ts-expect-error
import GeneratedLogoValidations from 'qonto/validations/generated-logo';

// @ts-expect-error
export default class GeneratedLogoModel extends Model.extend(GeneratedLogoValidations) {
  @attr('string') declare locale: string;
  @attr('string') declare status: string;
  @attr('string') declare userInput: string;
  @attr('string', { defaultValue: 'any' }) declare color: string;
  @attr('string', { defaultValue: 'any' }) declare style: string;
  @attr('string') declare url: string;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @waitFor
  async uploadLogo() {
    return await apiAction(this, { method: 'PUT', path: 'upload' });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'generated-logo': GeneratedLogoModel;
  }
}
