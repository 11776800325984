import { attr } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class FinancingInstallmentModel extends Subject {
  @attr('number') declare currentInstallmentNumber: number;
  @attr('number') declare totalInstallmentsNumber: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'financing-installment': FinancingInstallmentModel;
  }
}
