import Model, { attr, hasMany } from '@ember-data/model';

export default class ApprovalWorkflowStateModel extends Model {
  // @ts-expect-error
  @hasMany('approval-workflow-state/condition', { async: false, inverse: null }) conditions;
  // @ts-expect-error
  @hasMany('approval-workflow-state/step', { async: false, inverse: null }) steps;
  @attr('boolean') declare isFallback: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow-state': ApprovalWorkflowStateModel;
  }
}
