import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type LabelModel from 'qonto/models/label';
// @ts-expect-error
import Validations from 'qonto/validations/label-list';

// @ts-expect-error
export default class LabelListModel extends Model.extend(Validations) {
  declare id: string;

  @attr('string') declare name: string;
  @attr('string') declare color: string;
  @attr('number') declare rank: number;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: 'labelLists' }) organization;
  @hasMany('label', { async: false, inverse: 'labelList' }) declare labels: LabelModel[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'label-list': LabelListModel;
  }
}
