import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class ExternalBankRecommendationsBundle extends Model {
  @attr('string') declare bankName: string;
  // @ts-expect-error
  @attr('hash') logo;
  @attr('string') declare status: string;

  @hasMany('external-bank-recommendation', {
    async: false,
    inverse: 'externalBankRecommendationsBundle',
  })
  // @ts-expect-error
  recommendations;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'external-bank-recommendations-bundle': ExternalBankRecommendationsBundle;
  }
}
