import Model, { attr, belongsTo } from '@ember-data/model';

export default class ReceiptReminderModel extends Model {
  @attr('boolean', { defaultValue: true }) declare reminder: boolean;
  @attr('boolean', { defaultValue: true }) declare summary: boolean;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receipt-reminder': ReceiptReminderModel;
  }
}
