import Model, { attr } from '@ember-data/model';

export default class F24InailTaxItemModel extends Model {
  // @ts-expect-error
  @attr branchCode;
  // @ts-expect-error
  @attr companyCode;
  // @ts-expect-error
  @attr checkCode;
  // @ts-expect-error
  @attr referenceNumber;
  // @ts-expect-error
  @attr taxType;
  @attr('number') declare taxAmount: number;
  @attr('number') declare compensationAmount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/inail-tax-item': F24InailTaxItemModel;
  }
}
