import Model, { attr, belongsTo } from '@ember-data/model';

export default class DiscountModel extends Model {
  @attr('string') declare finishOn: string;
  @attr('string') declare recurrenceOn: string;
  @attr('string') declare voucherCode: string;
  @attr('string') declare voucherUsed: string;
  @attr('string') declare voucherType: string;
  @attr('string') declare voucherFeeType: string;
  @attr('number') declare voucherAmount: number;
  @attr('number') declare voucherMonthDuration: number;
  @attr('string') declare voucherAmountCurrency: string;
  // @ts-expect-error
  @attr voucherRestrictions;
  @attr('boolean') declare discountValid: boolean;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    discount: DiscountModel;
  }
}
