/* eslint-disable @qonto/no-import-roles-constants */
import Model, { attr, belongsTo } from '@ember-data/model';

import { ROLES } from 'qonto/constants/membership';
import { areArraysEqualOrBothNull } from 'qonto/utils/array';

// @ts-expect-error
import KycKybUpdateMembershipChangeRequestValidations from '../validations/kyc-kyb-update-membership-change-request';

// @ts-expect-error
export default class KycKybUpdateMembershipChangeRequest extends Model.extend(
  KycKybUpdateMembershipChangeRequestValidations
) {
  /**
   * @type string
   * @example "Paris"
   */
  // @ts-expect-error
  @attr addressCity;

  /**
   * @type string
   * @example "FR"
   */
  // @ts-expect-error
  @attr addressCountry;

  /**
   * @type string
   * @example "Rue La Fayette"
   */
  // @ts-expect-error
  @attr addressFirstLine;

  /**
   * @type string | null
   * @example "20B"
   */
  // @ts-expect-error
  @attr addressSecondLine;

  /**
   * @type string | null
   * @example "Suite 10"
   */
  // @ts-expect-error
  @attr addressThirdLine;

  /**
   * @type string
   * @example "75009"
   */
  // @ts-expect-error
  @attr addressZipCode;

  /** @type date */
  // @ts-expect-error
  @attr birthdate;

  /** @type string */
  // @ts-expect-error
  @attr birthCity;

  /** @type string */
  // @ts-expect-error
  @attr birthCountry;

  /** @type boolean */
  // @ts-expect-error
  @attr corporateOfficer;

  /** @type string */
  // @ts-expect-error
  @attr firstName;

  /** @type string */
  // @ts-expect-error
  @attr lastName;

  /** @type boolean */
  // @ts-expect-error
  @attr legalRepresentative;

  /**
   * @type string
   * @example "FR"
   */
  // @ts-expect-error
  @attr nationality;

  /**
   * @type string[] | null
   * @example ["FR", "US"]
   */
  // @ts-expect-error
  @attr nationalities;

  /**
   * @type string
   * @example "CEO"
   */
  // @ts-expect-error
  @attr position;

  /**
   * The two-letter country code of the country where the person pays
   * their taxes.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   * @example "FR"
   */
  // @ts-expect-error
  @attr taxCountry;

  /**
   * The tax identification number of the person in the country
   * represented by `taxCountry`.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   */
  // @ts-expect-error
  @attr taxIdentificationNumber;

  /** @type boolean */
  // @ts-expect-error
  @attr ubo;

  /** @type string */
  // @ts-expect-error
  @attr gender;

  /** @type string | null
   *  @example "managing_partner"
   */
  // @ts-expect-error
  @attr uboConnectionToOrganization;

  /**
   * @type {string | null}
   * @description If `uboConnectionToOrganization` is "other",` uboConnectionToOrganizationRaw` can be any string.
   * Otherwise, it must match `uboConnectionToOrganization`'s specific value, such as "managing_partner".
   * @example "A friend of the partner"
   */
  // @ts-expect-error
  @attr uboConnectionToOrganizationRaw;

  // @ts-expect-error
  @attr usTaxPayer;
  // @ts-expect-error
  @attr taxPayerOutsideLegalCountry;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) membership;

  /** @type {KycKybUpdateProcess} */
  @belongsTo('kycKybUpdateProcess', {
    async: true,
    inverse: 'kycKybUpdateMembershipChangeRequests',
  })
  // @ts-expect-error
  kycKybUpdateProcess;

  /** @type {File} */
  // @ts-expect-error
  @belongsTo('file', { async: true, inverse: null }) kycKybUpdatePoiFile;

  get needsTaxData() {
    return this.ubo || this.membership.get('role') === ROLES.OWNER;
  }

  get needsTaxNumber() {
    return (
      this.kycKybUpdateProcess.get('organization.legalCountry') !== 'FR' || this.taxCountry !== 'FR'
    );
  }

  get hasMissingInformation() {
    return (
      !this.firstName || !this.lastName || (this.owner && !this.nationality) || !this.birthCity
    );
  }

  get differsFromOriginal() {
    if (
      this.addressCity === this.membership.get('address')?.city &&
      this.addressCountry === this.membership.get('address')?.country &&
      this.addressFirstLine === this.membership.get('address')?.firstLine &&
      this.addressSecondLine === this.membership.get('address')?.secondLine &&
      this.addressThirdLine === this.membership.get('address')?.thirdLine &&
      this.addressZipCode === this.membership.get('address')?.zipcode &&
      this.birthdate === this.membership.get('birthdate') &&
      this.birthCity === this.membership.get('birthCity') &&
      this.birthCountry === this.membership.get('birthCountry') &&
      this.corporateOfficer === this.membership.get('corporateOfficer') &&
      this.firstName === this.membership.get('firstName') &&
      this.lastName === this.membership.get('lastName') &&
      this.gender === this.membership.get('gender') &&
      this.legalRepresentative === this.membership.get('corporateOfficer') &&
      this.nationality === this.membership.get('nationality') &&
      areArraysEqualOrBothNull(this.nationalities, this.membership.get('nationalities')) &&
      this.position === this.membership.get('position') &&
      this.taxCountry === this.membership.get('taxResidenceCountry') &&
      this.taxIdentificationNumber === this.membership.get('taxResidenceTaxIdentificationNumber') &&
      this.ubo === this.membership.get('ubo')
    ) {
      return false;
    } else {
      return true;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'kyc-kyb-update-membership-change-request': KycKybUpdateMembershipChangeRequest;
  }
}
