import Model, { attr, belongsTo } from '@ember-data/model';

// @ts-expect-error
import Validations from 'qonto/validations/referral';

// @ts-expect-error
export default class ReferralModel extends Model.extend(Validations) {
  @attr('string') declare code: string;
  @attr('string') declare status: string;
  @attr('string') declare email: string;
  @attr('string') declare referreeName: string;
  @attr('number') declare rewardAmount: number;
  @attr('number') declare rewardAmountCents: number;
  @attr('string') declare rewardAmountCurrency: string;

  @attr('date') declare registeredAt: Date;
  @attr('date') declare rewardedAt: Date;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  // @ts-expect-error
  createdAt;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) referree;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) referrer;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    referral: ReferralModel;
  }
}
