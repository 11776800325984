import Model, { attr, belongsTo } from '@ember-data/model';

export default class InstallmentModel extends Model {
  @attr('string') declare status: string;
  @attr('date') declare date: Date;
  // @ts-expect-error
  @attr totalAmount;

  // @ts-expect-error
  @belongsTo('financing', { async: false }) financing;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    installment: InstallmentModel;
  }
}
