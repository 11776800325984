import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import { INVOICE_SOURCES } from 'qonto/constants/supplier-invoice';
import type { Amount } from 'qonto/react/models/amount';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export type SuggestedTransaction = {
  id: string;
  score: number;
};

export default class SupplierInvoiceModel extends Model {
  @attr('string') declare invoiceNumber: string;
  // @ts-expect-error
  @attr fileName;
  @attr('string') declare supplierName: string;
  // @ts-expect-error
  @attr description;
  @attr declare totalAmount: Amount<string>;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr declinedNote;
  // @ts-expect-error
  @attr iban;
  // @ts-expect-error
  @attr source; // 'email_forward' | 'pay_by_invoice' | 'e_invoicing' | 'supplier_invoices' | 'integration'
  // @ts-expect-error
  @attr selfInvoiceId;
  // @ts-expect-error
  @attr requestTransfer;
  @attr('boolean', { defaultValue: false }) declare hasDuplicates: boolean;
  // @ts-expect-error
  @attr({ defaultValue: false }) hasDiscrepancies;
  // @ts-expect-error
  @attr isAttachmentInvoice;
  // @ts-expect-error
  @attr isAttachmentNonFinancial;
  @attr('hash', {
    defaultValue: () => ({}),
  })
  declare supplierSnapshot: {
    name: string;
    iban: string;
  } | null;
  // @ts-expect-error
  @attr supplierId;
  // @ts-expect-error
  @attr({ defaultValue: false }) isEinvoice;

  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr issueDate;
  /** @type {string} YYYY-MM-DD */
  @attr declare dueDate: string;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr paymentDate;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr scheduledDate;
  /** @type {string} ISO Date String  */
  // @ts-expect-error
  @attr createdAt;
  /** @type {string} ISO Date String  */
  // @ts-expect-error
  @attr analyzedAt; // Ex value: "2022-03-04T17:58:30+02:00"
  /** @type Array<{ status_code: number, reason: string, reason_message: string, timestamp: string }> | null **/
  // @ts-expect-error
  @attr({ defaultValue: null }) einvoicingLifecycleEvents;
  // @ts-expect-error
  @attr({ defaultValue: () => [] }) matchedTransactionsIds;
  // @ts-expect-error
  @attr('hash', { defaultValue: () => ({}) }) availableActions;
  // @ts-expect-error
  @attr('hash') approvalWorkflow;
  @attr() declare attachmentId: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('attachment', { async: false, inverse: null }) attachment;
  // @ts-expect-error
  @belongsTo('attachment', { async: false, inverse: null }) displayAttachment;

  /** @type boolean */
  // @ts-expect-error
  @attr({ defaultValue: false }) hasSuggestedCreditNotes;
  /** @type boolean */
  // @ts-expect-error
  @attr({ defaultValue: false }) isCreditNote;
  /** @type MoneyObject */
  @attr declare payableAmount?: Amount<string>;
  /** @type Array<SupplierInvoice> */
  // @ts-expect-error
  @attr({ defaultValue: () => [] }) relatedInvoices;
  /** @type MoneyObject */
  // @ts-expect-error
  @attr totalAmountCreditNotes;

  @attr({ defaultValue: () => [] }) declare suggestedTransactions: SuggestedTransaction[];

  // @ts-expect-error
  beneficiaryId;

  // @ts-expect-error
  @tracked approvalWorkflowState;

  @service declare sentry: Services['sentry'];

  async loadApprovalWorkflowState() {
    if (this.approvalWorkflow) {
      try {
        // @ts-expect-error
        this.approvalWorkflowState = (
          await this.store.query('approval-workflow-state', {
            request_type: 'supplier_invoice',
            request_id: this.approvalWorkflow.supplierInvoiceRequestId,
          })
        )[0];
      } catch (error) {
        if (
          ErrorInfo.for(error).shouldSendToSentry &&
          // @ts-expect-error
          !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
        ) {
          this.sentry.captureException(error);
        }
      }
    }
  }

  @waitFor
  // @ts-expect-error
  async markAsPaid(paymentDate) {
    let data = { supplier_invoice: { payment_date: paymentDate } };
    let response = await apiAction(this, { method: 'POST', path: 'mark_as_paid', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async convertToInvoice() {
    let response = await apiAction(this, { method: 'POST', path: 'convert_to_invoice' });
    this.store.pushPayload(response);
  }

  @waitFor
  async convertToCreditNote() {
    let response = await apiAction(this, { method: 'POST', path: 'convert_to_credit_note' });
    this.store.pushPayload(response);
  }

  @service declare organizationManager: Services['organizationManager'];

  get frenchEInvoicing() {
    return this.source
      ? this.source === INVOICE_SOURCES.eInvoicing &&
          this.organizationManager.organization.legalCountry === 'FR'
      : false;
  }

  get isGermanEInvoice() {
    return this.isEinvoice && this.organizationManager.organization.legalCountry === 'DE';
  }

  get isItalianEInvoice() {
    return this.source
      ? this.source === INVOICE_SOURCES.eInvoicing &&
          this.organizationManager.organization.legalCountry === 'IT'
      : false;
  }

  get isElectronicInvoice() {
    return (this.frenchEInvoicing || this.isGermanEInvoice || this.isItalianEInvoice) ?? false;
  }

  get hasRelatedInvoices() {
    return Boolean(this.relatedInvoices?.length);
  }

  get amountToPay() {
    return this.hasRelatedInvoices ? this.payableAmount : this.totalAmount;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'supplier-invoice': SupplierInvoiceModel;
  }
}
