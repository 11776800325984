import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

export default class IntegrationModel extends Model {
  @service declare store: Services['store'];

  @attr('string') declare login: string;
  @attr('string') declare secretKey: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  @waitFor
  async regenerateKey() {
    let response = await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
    });
    this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    integration: IntegrationModel;
  }
}
