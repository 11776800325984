import Model, { attr } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error: missing types
import { apiAction } from '@mainmatter/ember-api-actions';

export default class EinvoicingFrSettingsModel extends Model {
  @attr('string') declare reason: string;
  @attr('string') declare einvoicingReceivingStatus:
    | 'enabled'
    | 'no_qonto'
    | 'disabled'
    | 'pending_creation'
    | 'pending_deletion';
  @attr('boolean', { defaultValue: false }) declare einvoicingSendingEnabled: boolean;
  @attr({ defaultValue: null }) declare addresses: Array<{
    isQonto: boolean;
    pdpName: string;
    address: string;
  }> | null;
  @attr('string') declare segmentation: 'ap_ar' | 'third_party' | 'standard';

  @waitFor
  async activate() {
    let response = await apiAction(this, {
      method: 'POST',
      path: 'activation',
    });

    this.store.pushPayload('einvoicing-fr-settings', response);
  }

  @waitFor
  async deactivate(reason: string, addresses: string[]) {
    let response = await apiAction(this, {
      method: 'POST',
      path: 'deactivation',
      data: {
        data: {
          type: 'settings',
          attributes: {
            reason,
            addresses,
          },
        },
      },
    });

    this.store.pushPayload('einvoicing-fr-settings', response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'einvoicing-fr-settings': EinvoicingFrSettingsModel;
  }
}
