import Model, { attr } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

// @ts-expect-error
import Validations from 'qonto/validations/concierge-request';

// @ts-expect-error
export default class ConciergeRequestModel extends Model.extend(Validations) {
  // @ts-expect-error
  @attr channel;
  // @ts-expect-error
  @attr inquiry;

  @waitFor
  async send() {
    return await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
      data: { channel: this.channel, content: this.inquiry },
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'concierge-request': ConciergeRequestModel;
  }
}
