import Model, { attr } from '@ember-data/model';

export default class SubscriptionsOptionModel extends Model {
  @attr('string') declare code: string;
  // @ts-expect-error
  @attr price;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscriptions-option': SubscriptionsOptionModel;
  }
}
