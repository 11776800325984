import { attr, belongsTo } from '@ember-data/model';

import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import Subject from 'qonto/models/subject';

export default class IncomeModel extends Subject {
  @attr('number') declare amount: number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string') declare emitterBic: string;
  @attr('string') declare emitterIban: string;
  @attr('string') declare emitterIdentification: string;
  @attr('string') declare emitterName: string;
  @attr('string') declare reference: string;
  @attr('boolean', { defaultValue: true }) declare isIBANAccount: boolean;
  @attr('string', { defaultValue: 'iban' }) declare accountType: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;

  // @ts-expect-error
  @reads('emitterBic') bic;
  // @ts-expect-error
  @reads('emitterIban') iban;

  get isIBANObfuscated() {
    return /^(?:••••|xxxx)/i.test(this.iban);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    income: IncomeModel;
  }
}
