import Model, { attr } from '@ember-data/model';

export default class RemuneratedAccountModel extends Model {
  /** @type {string} */
  // @ts-expect-error
  @attr accountId;
  /** @type {number} */
  // @ts-expect-error
  @attr currentMaturity;
  /** @type {number} */
  // @ts-expect-error
  @attr maxMaturity;
  /** @type {Date} */
  @attr('date') declare startDate: Date;
  /** @type {Date} */
  @attr('date') declare endDate: Date;
  /** @type {boolean} */
  // @ts-expect-error
  @attr isTaxApplicable;
  /** @type object {value: string, currency: string} */
  // @ts-expect-error
  @attr maxMaturityEarnings;
  /** @type {array} */
  // @ts-expect-error
  @attr interestRates;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'remunerated-account': RemuneratedAccountModel;
  }
}
