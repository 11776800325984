import Model, { attr, belongsTo } from '@ember-data/model';

// @ts-expect-error
import LegalEntityValidations from 'qonto/validations/legal-entity';

// @ts-expect-error
export default class LegalEntityModel extends Model.extend(LegalEntityValidations) {
  // @ts-expect-error
  @attr legalName;
  // @ts-expect-error
  @attr legalNumber;
  // @ts-expect-error
  @attr capitalAmount;

  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('stakeholder', { async: false, inverse: null }) stakeholder;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legal-entity': LegalEntityModel;
  }
}
