import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { reads } from 'macro-decorators';

export default class MultiBeneficiaryModel extends Model {
  // @ts-expect-error
  @attr('file') file;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @hasMany('bulkBeneficiaries', { async: true, inverse: 'multiBeneficiary' }) beneficiaries;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  // @ts-expect-error
  @reads('selectedBeneficiaries.length') selectedBeneficiariesCount;

  get selectedBeneficiaries() {
    // @ts-expect-error
    return this.beneficiaries.filter(item => get(item, 'selected') === true);
  }

  @waitFor
  async confirmFlight() {
    // @ts-expect-error
    let { organization_id, bank_account_id, file, beneficiaries } = this.serialize();
    let data = {
      multi_beneficiary: { organization_id, bank_account_id, file, beneficiaries },
    };

    return await apiAction(this, { method: 'POST', path: 'confirm', data });
  }

  @waitFor
  async submit() {
    // @ts-expect-error
    let { organization_id, bank_account_id, file, beneficiaries } = this.serialize();
    let data = {
      multi_beneficiary: { organization_id, file, bank_account_id, beneficiaries },
    };

    return await apiAction(this, { method: 'PUT', path: 'submit', data });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'multi-beneficiary': MultiBeneficiaryModel;
  }
}
