import Model, { attr, hasMany } from '@ember-data/model';

import { equal } from 'macro-decorators';

import { FINANCING_TRANSFER_STATUS } from 'qonto/constants/financing';

export default class FinancingModel extends Model {
  @attr('string') declare status: string;
  @attr('string') declare activityTag: string;
  @attr('string') declare beneficiaryName: string;
  @attr('string') declare repaymentStatus: string;
  @attr('string') declare bankAccountName: string;
  @attr('number') declare paidInstallments: number;
  @attr('number') declare totalInstallments: number;
  @attr('date') declare nextInstallmentAt: Date;
  @attr('date') declare completedAt: Date;
  @attr('date') declare settledAt: Date;
  // @ts-expect-error
  @attr beneficiaryLogo;
  // @ts-expect-error
  @attr nextInstallmentTotalAmount;
  // @ts-expect-error
  @attr financedAmount;
  // @ts-expect-error
  @attr totalAmount;
  // @ts-expect-error
  @attr interestAmount;
  // @ts-expect-error
  @attr penaltyFeesAmount;
  // @ts-expect-error
  @attr remainingAmount;

  // @ts-expect-error
  @hasMany('installment', { async: false }) installments;
  // @ts-expect-error
  @hasMany('transaction', { async: false }) transactions;

  // @ts-expect-error
  @equal('status', FINANCING_TRANSFER_STATUS.PROCESSING) isProcessing;
  // @ts-expect-error
  @equal('status', FINANCING_TRANSFER_STATUS.DECLINED) isDeclined;

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.isProcessing) {
      avatarStatusIconSrc = 'status_processing-s';
    }
    if (this.isDeclined) {
      avatarStatusIconSrc = 'status_stop-xs';
    }

    return {
      mediumLogo: this.beneficiaryLogo?.medium ?? activityTagSVG,
      smallLogo: this.beneficiaryLogo?.small ?? activityTagSVG,
      hasBorder: Boolean(this.beneficiaryLogo?.medium),
      icon: avatarStatusIconSrc,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    financing: FinancingModel;
  }
}
