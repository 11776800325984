import Model, { belongsTo } from '@ember-data/model';

export default class HubActivationModel extends Model {
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('hub-application', { async: false, inverse: 'activation' }) application;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hub-activation': HubActivationModel;
  }
}
