import Model, { attr } from '@ember-data/model';

export default class EinvoicingSettingsModel extends Model {
  @attr('boolean', { defaultValue: false }) declare einvoicingOnboarded: boolean;
  @attr('boolean', { defaultValue: false }) declare einvoicingConsentGiven: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'einvoicing-settings': EinvoicingSettingsModel;
  }
}
