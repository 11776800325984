import Model, { attr } from '@ember-data/model';

import { type PaymentMethods } from '@repo/domain-kit/shared';

export default class PaymentLinkMethodModel extends Model {
  @attr declare method: PaymentMethods;
  @attr declare enabled: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-link-method': PaymentLinkMethodModel;
  }
}
