import Model, { attr } from '@ember-data/model';

export default class CompanyCreationOrganization extends Model {
  @attr('date') declare createdAt: Date;
  @attr('string') declare slug: string;
  @attr('string') declare name: string;
  // @ts-expect-error
  @attr('file') avatarThumb;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cc-organization': CompanyCreationOrganization;
  }
}
