import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

export default class BudgetTransactionModel extends Model {
  // @ts-expect-error
  @attr activityTag;
  // @ts-expect-error
  @attr amount;
  // @ts-expect-error
  @attr counterpartyName;
  // @ts-expect-error
  @attr emittedAt;
  // @ts-expect-error
  @attr operationMethod;
  // @ts-expect-error
  @attr status;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;

  operationMethodTranslation = {
    // @ts-expect-error
    biller: this.intl.t('transactions.operation-types.biller'),
    // @ts-expect-error
    card: this.intl.t('transactions.operation-types.card'),
    // @ts-expect-error
    cheque: this.intl.t('transactions.operation-types.cheque'),
    // @ts-expect-error
    direct_debit: this.intl.t('transactions.operation-types.direct-debit'),
    // @ts-expect-error
    pagopa_payment: this.intl.t('transactions.operation-types.pagopa-payment'),
    // @ts-expect-error
    tax: this.intl.t('transactions.operation-types.tax'),
    // @ts-expect-error
    transfer: this.intl.t('transactions.operation-types.transfer'),
  };

  get operationMethodName() {
    return (
      // @ts-expect-error
      this.operationMethodTranslation[this.operationMethod] ??
      this.intl.t('transactions.operation-types.other')
    );
  }

  get initiatorMembershipId() {
    // @ts-expect-error
    return this.belongsTo('initiator').id();
  }

  get initiatorMembership() {
    // @ts-expect-error
    return this.belongsTo('initiator').value();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'budget-transaction': BudgetTransactionModel;
  }
}
