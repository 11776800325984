import Model, { attr, belongsTo } from '@ember-data/model';

import type MembershipModel from './membership';
import type OrganizationModel from './organization';

export default class GmiSolutionInstanceModel extends Model {
  @attr('string') declare userId: string;
  @attr('string') declare status: string;
  @attr('string') declare gmiStatus: string;
  @attr('string') declare troubleshootingUrl: string;
  @attr('string') declare solutionId: string;
  @attr('string') declare solutionInstanceId: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  // Enrichment attributes
  // Enrichment attributes
  @attr('string') declare name: string;
  @attr('string') declare logoUrl: string;

  @belongsTo('organization', { async: false, inverse: null })
  declare organization: OrganizationModel;

  @belongsTo('membership', { async: true, inverse: null })
  declare membership: MembershipModel;

  enrichWith({ name, logoUrl }: { name: string; logoUrl: string }): void {
    this.set('name', name);
    this.set('logoUrl', logoUrl);
  }
}

// Add to model registry
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'gmi-solution-instance': GmiSolutionInstanceModel;
  }
}
