import Model, { attr, belongsTo } from '@ember-data/model';

export default class MembershipKybDetails extends Model {
  // @ts-expect-error
  @attr usTaxPayer;
  // @ts-expect-error
  @attr taxPayerOutsideLegalCountry;
  // @ts-expect-error
  @attr uboConnectionToOrganization;
  // @ts-expect-error
  @attr uboConnectionToOrganizationRaw;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: 'kybDetails' }) membership;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'membership-kyb-detail': MembershipKybDetails;
  }
}
