import Model, { attr, belongsTo } from '@ember-data/model';

import CURRENCIES from 'qonto/constants/currencies';

export default class CreditNoteModel extends Model {
  @attr('number') declare amount: number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string') declare reference: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  // Temporary until deletion
  isCreditNote = true;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'credit-note': CreditNoteModel;
  }
}
