import { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import Subject from 'qonto/models/subject';

export default class NrcPaymentModel extends Subject {
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @attr('string') declare canceledAt: string;
  @attr('string') declare declineReason: string;
  @attr('string') declare executionDate: string;
  @attr('string') declare nrc: string;
  @attr('string') declare receiptUrl: string;
  @attr('string') declare status: string;
  @attr('string') declare statusGroup: string;
  @attr('string') declare taxCode: string;
  @attr('string') declare taxDescription: string;
  @attr('string') declare type: string;
  @attr('string') declare acronym: string;
  @attr('string') declare documentId: string;
  @attr('string') declare fiscalYear: string;
  @attr('boolean') declare isPayingForSomeoneElse: boolean;
  @attr('boolean') declare isPaymentInInstallments: boolean;
  @attr('boolean') declare isPreDeclaration: boolean;
  @attr('string') declare legalName: string;
  @attr('string') declare period: string;
  @attr('string') declare receiptCode: string;
  // @ts-expect-error
  @attr amount;

  get avatarInfo() {
    let activityTagSVG = `/icon/category/tax-m.svg`;

    return {
      mediumLogo: activityTagSVG,
      smallLogo: activityTagSVG,
      hasBorder: false,
    };
  }

  @waitFor
  async createPayment() {
    let payment = this.serialize();

    let data = {
      payment: {
        // @ts-expect-error
        acronym: payment.acronym === '' ? null : payment.acronym,
        // @ts-expect-error
        document_id: payment.document_id,
        // @ts-expect-error
        fiscal_year: payment.fiscal_year,
        // @ts-expect-error
        is_paying_for_someone_else: payment.is_paying_for_someone_else,
        // @ts-expect-error
        is_payment_in_installments: payment.is_payment_in_installments,
        // @ts-expect-error
        is_pre_declaration: payment.is_pre_declaration,
        // @ts-expect-error
        legal_name: payment.legal_name,
        // @ts-expect-error
        period: payment.period,
        // @ts-expect-error
        receipt_code: payment.receipt_code,
        // @ts-expect-error
        amount: payment.amount,
        // @ts-expect-error
        bank_account_id: payment.bank_account_id,
        // @ts-expect-error
        tax_code: payment.tax_code,
      },
    };

    let response = await apiAction(this, {
      method: 'POST',
      data,
    });
    return response;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nrc-payment': NrcPaymentModel;
  }
}
