import Model, { hasMany } from '@ember-data/model';

export default class ApprovalWorkflowRulesetModel extends Model {
  // @ts-expect-error
  @hasMany('approval-workflow/condition', { async: false, inverse: null }) conditions;
  // @ts-expect-error
  @hasMany('approval-workflow/step', { async: false, inverse: null }) steps;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow/ruleset': ApprovalWorkflowRulesetModel;
  }
}
