import Model, { attr } from '@ember-data/model';

export default class BulkActionModel extends Model {
  @attr('string') declare class: string;
  @attr('string') declare organizationId: string; // deprecated
  @attr('string') declare bankAccountId: string;

  // @ts-expect-error
  @attr ids;
  // @ts-expect-error
  @attr fields;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bulk-action': BulkActionModel;
  }
}
