import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

// @ts-expect-error
import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';

export default class SolutionInstanceModel extends Model {
  // @ts-expect-error
  @attr solutionId;
  // @ts-expect-error
  @attr status;
  @attr('date') declare createdAt: Date;

  // Enrichment attributes
  // Enrichment attributes
  @attr('string') declare name: string;
  @attr('string') declare logoUrl: string;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) membership;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  // @ts-expect-error
  @tracked wizardUrl;
  // @ts-expect-error
  @tracked wizardIsPopup;

  get isEnabled() {
    return this.status === SOLUTION_INSTANCE_STATUS.ENABLED;
  }

  // TODO remove this logic with feature--bolean-gmi-async-flow
  // @ts-expect-error
  enrichWith({ name, logoUrl }) {
    this.set('name', name);
    this.set('logoUrl', logoUrl);
  }

  @waitFor
  async getWizardURL() {
    let response = await apiAction(this, { method: 'POST', path: 'wizard' });
    this.wizardUrl = response.wizard.url;
    this.wizardIsPopup = response.wizard.is_popup;
  }

  @waitFor
  async enable() {
    let response = await apiAction(this, { method: 'PATCH', path: 'enable' });
    this.status = response.solution_instance?.status;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'solution-instance': SolutionInstanceModel;
  }
}
