import Model, { attr, belongsTo } from '@ember-data/model';

// @ts-expect-error
import { DEFAULT_FIELD, DEFAULT_OPERATOR } from 'qonto/constants/reminders-configuration';
// @ts-expect-error
import RemindersConfigurationRuleValidations from 'qonto/validations/reminders-configuration/rule';

// @ts-expect-error
export default class RemindersConfigurationRuleModel extends Model.extend(
  RemindersConfigurationRuleValidations
) {
  @attr('string', { defaultValue: DEFAULT_OPERATOR }) declare operator: string;
  @attr('string', { defaultValue: DEFAULT_FIELD }) declare field: string;
  @attr('number') declare offsetDays: number;
  @attr('string') declare emailTitle: string;
  @attr('string') declare emailBody: string;

  // @ts-expect-error
  @belongsTo('reminders-configuration', { async: false, inverse: 'rules' }) remindersConfiguration;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reminders-configuration/rule': RemindersConfigurationRuleModel;
  }
}
