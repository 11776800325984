import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-ignore we don't have the type for apiAction
import { apiAction } from '@mainmatter/ember-api-actions';

import type { RIBA_STATUS } from 'qonto/constants/riba';
import type { Amount } from 'qonto/react/models/amount';

type Status = (typeof RIBA_STATUS)[keyof typeof RIBA_STATUS];

export default class RibaPaymentModel extends Model {
  @service declare organizationManager: any;

  @attr declare amount: Amount;
  @attr('string') declare status: Status;
  @attr('date') declare approvedAt: Date;
  @attr('string') declare bankAccountId: string;
  @attr('date') declare canceledAt: Date;
  @attr('string') declare creditorName: string;
  @attr('date') declare debitedAt: Date;
  @attr('date') declare executionDate: Date;
  @attr('string') declare failureReason: string;
  @attr('string') declare receiptUrl: string;
  @attr('date') declare receivedAt: Date;
  @attr('string') declare reference: string;
  @attr('date') declare rejectedAt: Date;
  @attr('date') declare declinedAt: Date;
  @attr('string') declare ribaNumber: string;
  @attr('boolean') declare canUpdate: boolean;

  @waitFor
  static async updateBulk(
    store: any,
    action: 'approve' | 'reject',
    bankAccountId: string,
    ids: string[]
  ) {
    let model = store.createRecord('riba-payment');
    try {
      await apiAction(model, {
        method: 'PATCH',
        data: {
          action,
          bank_account_id: bankAccountId,
          ids,
        },
      });
    } finally {
      model.unloadRecord();
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'riba-payment': RibaPaymentModel;
  }
}
