import Model, { attr } from '@ember-data/model';

export default class F24ImuTaxItemModel extends Model {
  // @ts-expect-error
  @attr taxCode;
  // @ts-expect-error
  @attr receiverCode;
  @attr('boolean') declare isAmendment: boolean;
  @attr('boolean') declare isRealEstateVariation: boolean;
  @attr('boolean') declare isDeposit: boolean;
  @attr('boolean') declare isFinalPayment: boolean;
  @attr('number') declare realEstateCount: number;
  // @ts-expect-error
  @attr referenceMonth;
  // @ts-expect-error
  @attr referenceYear;
  @attr('number') declare taxAmount: number;
  @attr('number') declare compensationAmount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/imu-tax-item': F24ImuTaxItemModel;
  }
}
