import Model, { attr, belongsTo } from '@ember-data/model';

export default class BankConnectionProvider extends Model {
  @attr('string') declare source: string;

  // @ts-expect-error
  @belongsTo('bank-connection', { async: false, inverse: null }) connection;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bank-connection-provider': BankConnectionProvider;
  }
}
