import Model, { attr, hasMany } from '@ember-data/model';

export default class ConnectionModel extends Model {
  // @ts-expect-error
  @attr organizationId;
  // @ts-expect-error
  @attr applicationId;
  // @ts-expect-error
  @attr activationId;
  // @ts-expect-error
  @attr externalService;
  // @ts-expect-error
  @attr params;
  // @ts-expect-error
  @hasMany('hub-notification-rules', { async: true, inverse: 'connection' }) rules;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'hub-connection': ConnectionModel;
  }
}
