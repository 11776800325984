import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo } from '@ember-data/model';
import { isArray } from '@ember/array';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import { F24_STATUS } from 'qonto/constants/f24';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

export default class F24OrderModel extends Model {
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @attr name;
  // @ts-expect-error
  @attr status;
  @attr('string') declare documentAmount: string;
  // @ts-expect-error
  @attr currency;
  // @ts-expect-error
  @attr createdAt;
  // @ts-expect-error
  @attr expirationDate;
  // @ts-expect-error
  @attr statusGroup;
  // @ts-expect-error
  @attr receiptUrl;
  // @ts-expect-error
  @attr canceledAt;
  // @ts-expect-error
  @attr declinedReason;
  // @ts-expect-error
  @attr isCancelable;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: true, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) membership;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('f24/payer', { async: false, inverse: null }) payer;
  // @ts-expect-error
  @belongsTo('f24/payer-agent', { async: false, inverse: null }) payerAgent;
  // @ts-expect-error
  @belongsTo('f24/tax-information', { async: false, inverse: null }) taxes;

  @waitFor
  async cancelF24() {
    return await apiAction(this, {
      method: 'PUT',
      path: 'cancel',
    });
  }

  @waitFor
  async prefill() {
    let prefilledModel = this;

    // Prefill is a "best effort" operation, when it fails
    // the user shouldn't be notified.
    // We just send the error to Sentry if needed.
    try {
      let response = await apiAction(this, {
        method: 'GET',
        path: 'prefill',
      });
      prefilledModel = this._normalizeAndSetPropertiesPrefill(response);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return prefilledModel;
  }

  // @ts-expect-error
  _normalizeAndSetPropertiesPrefill(response) {
    let hash = this._normalizePrefill(response);
    this.setProperties(hash.data.attributes);
    // instantiate related records
    // @ts-expect-error
    let records = hash.included.reduce((records, { type, attributes }) => {
      records[type] = this.store.createRecord(type, attributes);
      return records;
    }, {});

    // associate related records
    records['f24/payer']?.set('address', records['f24/address']);
    this.set('payer', records['f24/payer']);
    return this;
  }

  // @ts-expect-error
  _normalizePrefill(response) {
    // @ts-expect-error
    let serializer = this.store.serializerFor('f24Order');
    // @ts-expect-error
    return serializer.normalizeSingleResponse(
      this.store,
      this.constructor,
      response,
      null,
      'findRecord'
    );
  }

  @waitFor
  async validate() {
    /**
     * The 422 API response is the source of truth of the errors and it can respond with either:
     * - a list of `invalid_field` each with a pointer that points to the relevant form field.
     *   (These are removed when updating the relevant field)
     * OR
     * - a generic `generic_error` with "/" as a pointer that shows a generic error message.
     *   (This is never removed when updating the form)
     *
     * Since it can return one of the above depending on what the user submits,
     * (eg 1st call returns one `invalid_field`, next call `generic_error`, next one `invalid_field` again)
     * we have to manually remove the `generic_error` since it could be shown even when the
     * API reponse doesn't contain it.
     *
     * Use case example:
     * 1. User fills the form and submits
     * 2. API response 422 with `generic_error`
     *    2a. the error is added to the f24-order model
     * 3. User adjust the form and submits
     * 4. API response 422 with `invalid_field`
     *   4a. the error is added to the model specified in the points
     * Without the following code, we would have still the `generic_error` error,
     * even tho the API just responded with `invalid_field`.
     */
    this.errors.remove('payer');
    this.errors.remove('payerAgent');
    this.taxes?.errors.remove('erario');
    this.errors.remove('taxes/inps/taxList');
    this.errors.remove('taxes/regioni/taxList');
    this.taxes?.errors.remove('imu');
    this.errors.remove('taxes/otherInstitutions/inail/taxList');
    this.errors.remove('taxes/otherInstitutions/others');
    this.errors.remove('/');

    let data = { f24_order: this.serialize() };

    try {
      return await apiAction(this, {
        method: 'POST',
        path: 'validate',
        data,
      });
    } catch (error) {
      if (this.isInvalidErrorWithPointers(error)) {
        // @ts-expect-error
        error.errors.forEach(e => this.addError(e.source.pointer, e.code));
      }
      throw error;
    }
  }

  @waitFor
  async create() {
    return await apiAction(this, {
      method: 'POST',
      data: { f24_order: this.serialize() },
    });
  }

  // @ts-expect-error
  isInvalidErrorWithPointers(error) {
    // @ts-expect-error
    return error instanceof InvalidError && error.errors;
  }

  // @ts-expect-error
  addError(pointer, code) {
    // matches nested attribute errors 'taxes/erario/...'
    let pathParts = pointer.split('/');

    // error is for a relationship
    if (pathParts.length > 1) {
      let attribute = pathParts.pop();
      let item = this;

      for (let i = 0; item && i < pathParts.length; i++) {
        let path = pathParts[i];
        // @ts-expect-error
        item = isArray(item) ? item[path] : item.get(path);
      }

      if (item) {
        item.errors.add(attribute, code);
      } else {
        // if we can't find the submodel the error belongs to
        // assign it at the base
        this.errors.add(pointer, code);
      }
    } else {
      this.errors.add(pointer, code);
    }
  }

  @waitFor
  async fetchAvailableDates() {
    return await apiAction(this, {
      method: 'GET',
      path: 'available_dates',
    });
  }

  get hasReceipt() {
    return Boolean(this.receiptUrl);
  }

  get isCompletedPayment() {
    return this.status === F24_STATUS.COMPLETED;
  }

  get isTerminatedPayment() {
    return this.status === F24_STATUS.DECLINED || this.status === F24_STATUS.CANCELED;
  }

  get isNotCanceled() {
    return this.status !== F24_STATUS.CANCELED;
  }

  get erarioTotal() {
    return this.sumTotal(this.taxes.erario?.taxList);
  }

  get inpsTotal() {
    return this.sumTotal(this.taxes.inpsTaxList);
  }

  get regioniTotal() {
    return this.sumTotal(this.taxes.regioniTaxList);
  }

  get imuTotal() {
    return this.sumTotal(this.taxes.imu?.taxList);
  }

  get inailTotal() {
    return this.sumTotal(this.taxes.inailTaxList);
  }

  get othersTotal() {
    return this.sumTotal(this.taxes.others?.taxList);
  }

  get othersAndInailTotal() {
    return this.inailTotal + this.othersTotal;
  }

  get totalAmount() {
    let total =
      this.erarioTotal +
      this.inpsTotal +
      this.regioniTotal +
      this.imuTotal +
      this.othersAndInailTotal;

    return total.toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName, itemsArray = []) {
    return itemsArray.reduce((acc, item) => {
      let value = item[attributeName] || 0;
      return acc + Number(value);
    }, 0);
  }

  // @ts-expect-error
  sumTotal(itemsArray) {
    let totalCompensation = this.sumUp('compensationAmount', itemsArray);
    let totalTax = this.sumUp('taxAmount', itemsArray);

    return totalTax - totalCompensation;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24-order': F24OrderModel;
  }
}
