import Model, { attr } from '@ember-data/model';

export default class F24AddressModel extends Model {
  // @ts-expect-error
  @attr streetAddress;
  // @ts-expect-error
  @attr city;
  // @ts-expect-error
  @attr province;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'f24/address': F24AddressModel;
  }
}
