import Model, { attr } from '@ember-data/model';

export default class ApprovalWorkflowStateStepModel extends Model {
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr operation;
  // @ts-expect-error
  @attr approvers;
  // @ts-expect-error
  @attr value;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow-state/step': ApprovalWorkflowStateStepModel;
  }
}
