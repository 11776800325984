import { attr, belongsTo } from '@ember-data/model';

import Request from 'qonto/models/request';

export default class RequestFlashCard extends Request {
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bank-account', { async: true, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) approver;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) holder;
  // @ts-expect-error
  @belongsTo('card', { async: true, inverse: null }) card;

  @attr('number') declare paymentLifespanLimit: number;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr currency;
  // @ts-expect-error
  @attr processedAt;
  // @ts-expect-error
  @attr createdAt;
  // @ts-expect-error
  @attr preExpiresAt;
  // @ts-expect-error
  @attr warnings;
  @attr('string', { defaultValue: 'flash_card' }) declare requestType: string;

  get isExpired() {
    return this.warnings?.includes('pre_expires_at_in_the_past');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-flash-card': RequestFlashCard;
  }
}
